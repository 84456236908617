import React from "react";
import { Divider, Group, MultiSelect, Paper, Text, TextInput } from "@mantine/core";

import { notification } from "../../../utils/notification";
import { useBoxOfficeFormContext } from "./BoxOffice";

//API
import {
    IBoxOffice,
    useCreateBoxOfficeMutation,
    useUpdateBoxOfficeMutation
} from "../../../api/box-office";
import { useGetUsersQuery } from "../../../api/user";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";


const BoxOfficeForm = (props: { recordId?: string, onClose: () => void, record: IBoxOffice }) => {
    const { t } = useTranslation();
    const form = useBoxOfficeFormContext();
    const [createBoxOffice] = useCreateBoxOfficeMutation();
    const [updateBoxOffice] = useUpdateBoxOfficeMutation();


    const { data: usersData = { results: [] } } = useGetUsersQuery({ group: ["cashiers"] });


    async function onSubmit(values: IBoxOffice) {
        try {
            props.recordId ?
                await updateBoxOffice({ id: Number(props.recordId), ...values }).unwrap()
                :
                await createBoxOffice(values).unwrap();

            notification({
                type: "success",
                title: props.recordId ? t("boxOffices.notifications.updated") : t("boxOffices.notifications.created")
            });
            props.onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <form id={"boxOfficeForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values);
        })}>
            <TextInput
                label={t("boxOffices.form.name")}
                required
                {...form.getInputProps("name")}
            />
            <MultiSelect
                required
                mt={15}
                label={t("boxOffices.form.cashiers")}
                placeholder={t("common.select")}
                data={usersData.results.map((b) => {
                    return { value: String(b.id), label: `${b.first_name} ${b.last_name}` };
                })}
                comboboxProps={{
                    zIndex: 500
                }}
                {...form.getInputProps("cashiers")}
            />
            <Paper py={20}>
                <Text>{t("addresses.address")}</Text>
                <Divider my={10} />
                <Group gap={"lg"} justify={"space-between"}>
                    <TextInput
                        w={'47%'}
                        required
                        maxLength={2}
                        label={t("addresses.form.country")}
                        {...form.getInputProps("country")}
                    />
                    <TextInput
                        w={'47%'}
                        label={t("addresses.form.city")}
                        {...form.getInputProps("city")}
                    />
                </Group>
                <TextInput
                    mt={15}
                    label={t("addresses.form.street")}
                    {...form.getInputProps("street")}
                />

                <Group mt={15} gap={"lg"} justify={"space-between"}>
                    <TextInput
                        w={'30%'}
                        label={t("addresses.form.street_number")}
                        {...form.getInputProps("street_number")}
                    />
                    <TextInput
                        w={'30%'}
                        label={t("addresses.form.block")}
                        {...form.getInputProps("block")}
                    />
                    <TextInput
                        w={'30%'}
                        label={t("addresses.form.index")}
                        {...form.getInputProps("postcode")}
                    />
                </Group>
            </Paper>
        </form>
    );
};
export default BoxOfficeForm;
