import {createAsyncThunk} from "@reduxjs/toolkit";
import {HTTP} from "../../utils/http";
import axios from "axios";
import {ISalesState, onClickSeat, setIsActiveToken} from "./sales.slice";
import {api, isErrorWithMessage, showErrorMessages} from "../../app/api"
import {IBooking} from "../../api/booking";

export const activateBoxOffice = createAsyncThunk<
    any,
    { boxOfficeId: number },
    { rejectValue: string[], state: any }
>("sales/activateBoxOffice", async (payload, thunkApi) => {
    try {
        const state = thunkApi.getState().sales;
        const authState = thunkApi.getState().auth;
        const boxOffices = await HTTP.get("/sales/admin/box-offices/");
        const session = state.cashierToken ?
            await HTTP.get(`/sales/admin/box-offices/session-status/?token=${state.cashierToken}`) :
            false;
        const currentBoxOffice = boxOffices.data.results.find((f: {
            id: number,
            cashier?: { id: number }
        }) => f.cashier?.id === Number(authState.user.id));
        console.log(currentBoxOffice);

        const selectedBoxOffice = boxOffices.data.results.find((f: {
            id: number
        }) => f.id === Number(payload.boxOfficeId));

        if (session && session.data?.token_session && session.data?.token_session?.box_office.id) {
            await HTTP.post<any>(
                `/sales/admin/box-offices/${session.data.token_session.box_office.id}/complete-sales/`,
                JSON.stringify({
                    id: session.data.token_session.box_office.id
                })
            );
        } else {
            //close current session
            if (Boolean(currentBoxOffice?.token)) {
                await HTTP.post<any>(
                    `/sales/admin/box-offices/${currentBoxOffice.id}/complete-sales/`,
                    JSON.stringify({
                        id: Number(state.activeBoxOffice)
                    })
                );
            }
        }

        //close selected session if box office have token

        if (Boolean(selectedBoxOffice?.token) && selectedBoxOffice?.cashier?.id !== authState.user.id) {
            try {
                await HTTP.post<any>(
                    `/sales/admin/box-offices/${selectedBoxOffice.id}/complete-sales/`,
                    JSON.stringify({
                        id: Number(payload.boxOfficeId)
                    })
                );
            } catch (e) {
                console.log('selected box office', selectedBoxOffice);
                console.log(e);
            }
        }

        //create new session
        const newSession = await HTTP.post<{ token: string }>(
            `/sales/admin/box-offices/${payload.boxOfficeId}/start-sales/`,
            JSON.stringify({
                id: payload.boxOfficeId
            })
        );
        thunkApi.dispatch(api.util?.invalidateTags(["Event"]))
        return {
            token: newSession.data.token,
            boxOfficeId: payload.boxOfficeId
        };

    } catch (e) {
        if (axios.isAxiosError(e)) {
            if (e.response) {
                return thunkApi.rejectWithValue(e.response.data);
            }
        }
        return thunkApi.rejectWithValue(e as string[]);
    }
});


export const checkStatus = createAsyncThunk<
    any,
    undefined,
    { rejectValue: string, state: any }
>("sales/checkStatus", async (payload, thunkApi) => {
    try {
        const token = thunkApi.getState().sales.cashierToken;
        const res = await HTTP.get("/sales/admin/box-offices/session-status/?token=" + token);
        if (!res.data.token_session) {
            //reactivate automatically box office
            if (!res.data.last_session.closed_by && res.data.last_session.box_office.id) {
                thunkApi.dispatch(activateBoxOffice({boxOfficeId: res.data.last_session.box_office.id}));
                return;
            }
            //clear token
            thunkApi.dispatch(setIsActiveToken(false));
        }
        return res.data;
    } catch (e) {
        if (axios.isAxiosError(e)) {
            if (e.response) {
                return thunkApi.rejectWithValue(e.response.data.message);
            }
        }
        return thunkApi.rejectWithValue(e as string);
    }
});


type onClickPayload = {
    seatId: number,
    key: string,
    zone?: number | null,
    status?: "free" | "reserved" | "sold" | "disabled",
    ticket?: number
}
export const onSeatClickThunk = createAsyncThunk<any, onClickPayload, { rejectValue: string, state: any }>("sales/onSeatClick", async (payload, thunkApi) => {
    const state: ISalesState = thunkApi.getState().sales;
    const dispatch = thunkApi.dispatch;
    try {


        //sold tickets handler
        if (payload.status === "sold") {
            //store in state only sold tickets
            !state.selectedBooking?.tickets.length && dispatch(onClickSeat(payload));
            return;
        }


        //restrict select seats if sold tickets selected
        if (state.selectedSeats.length) return;

        if (!payload.zone) return;

        const selectedBooking = state.selectedBooking;

        if (selectedBooking && selectedBooking.event.id !== state.event.id) return;

        const isSelected = selectedBooking?.tickets.filter(f => f.status === "reserved").find(f => f.id === payload.ticket)

        if (!isSelected) {
            const reserve = await HTTP.post<IBooking>("/event/admin/bookings/reserve/", {
                booking: selectedBooking?.id,
                event: state.event.id,
                mode: "staff",
                items: [{seat: payload.seatId}],
            });

            return reserve.data;
        }

        if (selectedBooking) {
            const cancelReserve = await HTTP.post<IBooking>("/event/admin/bookings/cancel-reserve/", {
                booking: selectedBooking.id,
                only_tickets: [payload.ticket]
            })

            return cancelReserve.data
        }


    } catch (e) {
        console.log(e)
        if (axios.isAxiosError(e)) {
            console.log('seat select error', e.response?.data)
            if (isErrorWithMessage(e.response)) {
                showErrorMessages(e.response?.data);
            }
            if (e.response) {
                return thunkApi.rejectWithValue(e.response.data.message);
            }
        }
        return thunkApi.rejectWithValue(e as string);
    }
})
export const cancelReservation = createAsyncThunk<any, any, { rejectValue: string, state: any }>('sales/cancelReservation', async (payload, thunkApi) => {
    const state: ISalesState = thunkApi.getState().sales;
    try {
        if (state.selectedBooking?.id) {
            localStorage.removeItem("activeBooking")
            await HTTP.post("/event/admin/bookings/cancel-reserve/", {
                booking: state.selectedBooking.id
            })
            return true
        }
        return false;
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.log('cancel booking error', e.response?.data)
            if (isErrorWithMessage(e.response)) {
                showErrorMessages(e.response?.data);
            }
            if (e.response) {
                return thunkApi.rejectWithValue(e.response.data.message);
            }
        }
        return thunkApi.rejectWithValue(e as string);
    }
})