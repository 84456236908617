import React from "react";
import { Divider, Group, Paper, Text, TextInput } from "@mantine/core";

import { notification } from "../../../utils/notification";
import { useBuildingFormContext } from "./Building";

//API
import {
    IBuilding,
    useCreateBuildingMutation,
    useUpdateBuildingMutation
} from "../../../api/building";

import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";


const BuildingForm = (props: { recordId?: string, onClose: () => void }) => {
    const { t } = useTranslation();
    const form = useBuildingFormContext();
    const [createBuilding] = useCreateBuildingMutation();
    const [updateBuilding] = useUpdateBuildingMutation();


    async function onSubmit(values: IBuilding) {
        try {
            props.recordId ?
                await updateBuilding({ ...values, id: Number(props.recordId) }).unwrap() :
                await createBuilding(values).unwrap();

            notification({
                type: "success",
                title: props.recordId ? t('buildings.notifications.updated') : t('buildings.notifications.created')
            });
            props.onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <form id={"buildingsForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values);
        })}>
            <TextInput
                label={t("buildings.form.name")}
                required
                {...form.getInputProps("name")}
            />
            <Paper py={20}>
                <Text>{t("addresses.address")}</Text>
                <Divider my={10} />
                <Group gap={"lg"} justify={"space-between"}>
                    <TextInput
                        w={'47%'}
                        required
                        maxLength={2}
                        label={t("addresses.form.country")}
                        {...form.getInputProps("country")}
                    />
                    <TextInput
                        w={'47%'}
                        label={t("addresses.form.city")}
                        {...form.getInputProps("city")}
                    />
                </Group>
                <TextInput
                    mt={15}
                    label={t("addresses.form.street")}
                    {...form.getInputProps("street")}
                />

                <Group mt={15} gap={"lg"} justify={"space-between"}>
                    <TextInput
                        w={'30%'}
                        label={t("addresses.form.street_number")}
                        {...form.getInputProps("street_number")}
                    />
                    <TextInput
                        w={'30%'}
                        label={t("addresses.form.block")}
                        {...form.getInputProps("block")}
                    />
                    <TextInput
                        w={'30%'}
                        label={t("addresses.form.index")}
                        {...form.getInputProps("postcode")}
                    />
                </Group>
            </Paper>
        </form>
    );
};
export default BuildingForm;
