import { api } from "../app/api";
import { IUser } from "./user";

export interface IBoxOffice {
    id?: number;
    name?: string;
    entity?: number,
    country?: string;
    county?: string;
    city?: string;
    postcode?: string;
    street?: string;
    street_number?: string;
    block?: string;
    address_line?: string,
    cashiers?: number[]
    cashier?: IUser
    token?: number | null
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IBoxOfficeResponse {
    count: number;
    next: any;
    previous: any;
    results: IBoxOffice[];
}

export const buildingApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBoxOffices: build.query<IBoxOfficeResponse, {
            entity?: number,
            name?: string,
            ordering?: string,
            page?: number,
            page_size?: number,
            accessible?: boolean
        }>({
            query: (params) => ({
                url: `/sales/admin/box-offices/`,
                params: {
                    entity: params.entity,
                    page: params.page,
                    page_size: params.page_size,
                    accessible: params.accessible
                }
            }),
            providesTags: (result, error, tag) => [{ type: "BoxOffice", tag }]
        }),
        createBoxOffice: build.mutation<any, IBoxOffice>({
            query: (payload) => ({
                url: "/sales/admin/box-offices/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "BoxOffice" }]
        }),
        updateBoxOffice: build.mutation<any, IBoxOffice>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.id}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: (result) => (result ? [{ type: "BoxOffice" }] : ["ERROR"])
        }),
        readBoxOffice: build.query<any, { id: number }>({
            query: (params) => ({
                url: `/sales/admin/box-offices/${params.id}/`,
                method: "GET"
            }),
            providesTags: (result, error, tag) => [{ type: "BoxOffice", tag }]
        }),
        removeBoxOffice: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.id}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "BoxOffice" }]
        }),
        addCashiers: build.mutation<any, { id: number, users: number[] | [] }>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.id}/add-cashiers/`,
                method: "POST",
                body: {
                    users: payload.users
                }
            }),
            invalidatesTags: [{ type: "BoxOffice" }]
        }),
        removeCashiers: build.mutation<any, { id: number, users: any[] }>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.id}/remove-cashiers/`,
                method: "POST",
                body: {
                    users: payload.users
                }
            }),
            invalidatesTags: [{ type: "BoxOffice" }]
        })
    })
});

export const {
    useCreateBoxOfficeMutation,
    useUpdateBoxOfficeMutation,
    useRemoveBoxOfficeMutation,
    useReadBoxOfficeQuery,
    useLazyReadBoxOfficeQuery,
    useGetBoxOfficesQuery,
    useLazyGetBoxOfficesQuery,
    useRemoveCashiersMutation,
    useAddCashiersMutation
} = buildingApi;
