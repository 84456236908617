import axios from "axios";

// export const HTTP = axios.create({
//     baseURL: import.meta.env.VITE_BACKEND_URL,
//     withCredentials: true,
//     headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json"
//         // Origin: `https://ticket.retis.online`,
//         // Referer: `https://ticket.retis.online`,
//     }
// });
//
//
// HTTP.interceptors.request.use(function(config) {
//     const token = localStorage.getItem("userToken");
//     config.headers["X-CSRFToken"] = token || "";
//     return config;
// });


export const HTTP = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
});

HTTP.interceptors.request.use( function (config) {
    const token = localStorage.getItem("accessToken");
    config.headers["Authorization"] = "Bearer " + token;
    return config;
});