import { Divider, Paper, Text, useMantineColorScheme } from "@mantine/core";
import React from "react";

const InfoItem = ({ value = "", label = "", ValueC }: { value?: string | number, label?: string, ValueC?: React.ReactNode }) => {
    const { colorScheme } = useMantineColorScheme();

    return (
        <>
            <Paper style={{ display: "flex", flex: "1 1 auto", flexDirection: "row" }} mt={10} mb={10}>
                <Text style={{ minWidth: 220 }} fw={400}>{label}</Text>
                <div>
                    {ValueC ? ValueC : <Text c={colorScheme === "light" ? "gray.7" : "dark.2"}>{value}</Text>}
                </div>
            </Paper>
            <Divider color={colorScheme === "light" ? "gray.2" : "dark.4"} />
        </>
    );
};
export default InfoItem;
