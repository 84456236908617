import { DatePickerProps } from "@mantine/dates";
import dayjs from "dayjs";

export const getDayProps: DatePickerProps['getDayProps'] = (date) => {
    const today = new Date();
    if (dayjs(date).isSame(dayjs(today), 'day')) {
        return {
            style: {
                background: 'transparent',
                border: '1px solid var(--mantine-color-teal-filled)',
            },
        };
    }

    return {};
};