import {
    fetchBaseQuery,
    createApi,
    retry, FetchArgs,
    FetchBaseQueryError, BaseQueryFn, FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {notification} from "../utils/notification";
import {jwtDecode} from "jwt-decode";
import dayjs from "dayjs";
import {HTTP} from "../utils/http";
import {clearAuthorization, updateAccessToken} from "../pages/authorization/authorization.slice";
import {QueryReturnValue} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {Mutex} from 'async-mutex'

const mutex = new Mutex()

type TDecodedToken = {
    email: string,
    exp: number,
    iat: number,
    jti: string,
    token_type: string,
    user_id: number,
}


const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    credentials: "include",
    prepareHeaders: (headers, api: any) => {
        // const token = api.getState().auth.userToken;
        const accessToken = api.getState().auth.accessToken;

        if (accessToken) {
            // include token in req header
            //headers.set("X-CSRFToken", token);
            headers.set("Authorization", "Bearer " + accessToken)
            return headers;
        }
    }
});

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                const state: any = api.getState();
                try {
                    const refreshResult = await HTTP.post("/user/token/refresh/", {refresh: state.auth.refreshToken})

                    if (refreshResult.data) {
                        api.dispatch(updateAccessToken(refreshResult.data))
                        // retry the initial query
                        result = await baseQuery(args, api, extraOptions)
                    } else {
                        api.dispatch(clearAuthorization())
                    }
                } catch (e) {
                    api.dispatch(clearAuthorization())
                }
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
};

const baseQueryWithRetry = retry(baseQuery, {maxRetries: 0});

export const api = createApi({
    reducerPath: "splitApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Entity", "Event", "Hall", "Scheme", "User", "UserGroup", "Building", "ERROR", "Address", "BoxOffice", "Zone", "Booking", "EntityPermissions", "Analytics", "Invoice", "Seat", "EventContent"],
    endpoints: () => ({})
});

export const enhancedApi = api.enhanceEndpoints({
    endpoints: () => ({
        getPost: () => "test"
    })
});

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === "object" && error != null && "status" in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { data: string[] } {
    return (
        typeof error === "object" &&
        error != null &&
        "data" in error
    );
}

export function showErrorMessages(e: any) {
    if (Array.isArray(e)) {
        for (let err of e) {
            notification({type: "error", title: err || "Произошла ошибка"});
        }
        return;
    }

    notification({type: "error", title: e.detail ? e.detail : "Произошла ошибка"});
}

export function returnErrorMessage(e: any): string | string[] {
    if(Array.isArray(e)) {
        return e
    }
    return e.detail ? e.detail : "Произошла ошибка";
}