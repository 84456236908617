import PageContainer from "../../components/PageContainer";
import { Text } from "@mantine/core";

const Settings = () => {
    return (
        <PageContainer>
            <Text mt={0} mb={30} size={'xl'}>Настройки</Text>
        </PageContainer>
    );
};
export default Settings;
