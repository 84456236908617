import React, { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Divider, LoadingOverlay, Paper, Text } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useReadEventQuery } from "../../../../api/event";
import { reset, setEvent } from "../prices.slice";
import { useGetZonesQuery } from "../../../../api/zone";
import EventInfo from "../../../../components/EventInfo";
import { useTranslation } from "react-i18next";

const EventData = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useAppDispatch();
    const totalSeats = useAppSelector(state => state.schema.totalSeats);
    const data = useAppSelector(state => state.schema.data);
    const { data: fetchedZones = [] } = useGetZonesQuery({ id: params.id || "" }, {
        skip: !params.id
    });
    const { data: fetchedEvent = {}, isFetching } = useReadEventQuery({ id: Number(params.id) });

    const total = useMemo(() => {
        const dataArr = Object.keys(data.data);
        const f = dataArr.filter(f => data.data[f].zone);
        const r = dataArr.reduce((acc, curr) => {
            const zone = data.data[curr].zone ? fetchedZones.find(fz => fz.id === data.data[curr].zone) : undefined;
            if (!zone) return acc;
            acc.amount += Number(zone.price) || 0;

            return acc;
        }, { priced: 0, amount: 0 });

        return {
            priced: f.length,
            amount: r.amount
        };
    }, [JSON.stringify(fetchedZones), JSON.stringify(data.data)]);


    useEffect(() => {
        if (fetchedEvent) {
            dispatch(setEvent(fetchedEvent));
        }
    }, [JSON.stringify(fetchedEvent)]);

    const resetSelectedSeats = useCallback(() => {
        dispatch(reset())
    }, [])


    return (
        <>
            <MemoizedEventInfo event={fetchedEvent} resetSelectedSeats={resetSelectedSeats} />
            <Paper mt={10} px={10}>
                <Text size={"sm"}
                      pl={3}> {t(`priceEditor.withPrice`)} {total.priced} {t(`priceEditor.from`)} {totalSeats} {t(`priceEditor.seats`)}</Text>
                <Text size={"sm"} pl={3}
                      component={"span"}>{t(`priceEditor.total`)}: {new Intl.NumberFormat("md-MD").format(total.amount)}</Text>
                <Divider my={10} />
                <LoadingOverlay visible={isFetching} />
            </Paper>
        </>
    );
};
export default EventData;

const MemoizedEventInfo = React.memo(EventInfo);
