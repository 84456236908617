import EventInfo from "./EventInfo";
import Zones from "./Zones";
import Actions from "./Actions";
import AllowToBuy from "./AllowToBuy/AllowToBuy";

const Properties = () => {
    return (
        <>
            <div>
                <EventInfo />
                <AllowToBuy/>
                <Zones />
            </div>
            <Actions />
        </>
    );
};
export default Properties;
