import InfoTabs from "../../../components/CrudModal/InfoTabs";
import InfoItem from "../../../components/CrudModal/InfoItem";
import { IBuilding } from "../../../api/building";
import { useTranslation } from "react-i18next";

interface IBuildingRead extends Omit<IBuilding, "address" | "entity"> {
    address?: {
        id: number
        country: string
        city: string
        street: string
        street_number: string
    };
    entity: {
        id: number,
        name: string
    }
}

const EntityInfo = ({ record, isFetching }: { record: IBuildingRead, isFetching: boolean }) => {
    const { t } = useTranslation();
    return (
        <InfoTabs isFetching={isFetching} record={record}>
            {!isFetching && (
                <>
                    <InfoItem label={t("buildings.form.name")} value={record.name} />
                    <InfoItem label={t("entities.entity")} value={record.entity.name} />
                    <InfoItem label={t("buildings.form.address")} value={
                        `${record?.country} ${record?.city || ""} ${record?.street || ""} ${record?.street_number || ""}`} />
                </>
            )}
        </InfoTabs>
    );
};

export default EntityInfo;
