import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppState {
    loadingApp: boolean;
    userLanguage: "ru" | "ro";
}

const initialState: AppState = {
    loadingApp: false,
    userLanguage: "ru"
};
export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLoadingApp: (state, action) => {
            state.loadingApp = action.payload;
        },
        setUserLanguage: (state, action: PayloadAction<"ru" | "ro">) => {
            state.userLanguage = action.payload;
        }

    }
});

export const { setLoadingApp, setUserLanguage } = appSlice.actions;
export default appSlice.reducer;
