import classes from "./Custom-notification.module.css";
import { notifications } from "@mantine/notifications";

const colors = {
    success: "green",
    error: "red",
    warning: "orange",
    default: ""
};

export function notification({ type, message, title }: {
    type: "success" | "error" | "warning" | "default",
    title: string
    message?: string,
}) {

    notifications.show({
        color: colors[type],
        title,
        message,
        classNames: classes
    });
}
