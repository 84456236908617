import { useEffect, useState } from "react";
import { ActionIcon, Text } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { MoreHorizontal } from "lucide-react";

//Components
import { notification } from "../../../utils/notification";
import PageContainer from "../../../components/PageContainer";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import CrudModal from "../../../components/CrudModal/CrudModal";
import BoxOfficeForm from "./BoxOfficeForm";

//API
import {
    IBoxOffice,
    useGetBoxOfficesQuery, useReadBoxOfficeQuery,
    useRemoveBoxOfficeMutation
} from "../../../api/box-office";
import { useAppSelector } from "../../../app/hooks";
import BoxOfficeInfo from "./BoxOfficeInfo";
import { IUser } from "../../../api/user";
import { useTranslation } from "react-i18next";

const initialModalState = {
    opened: false,
    inEdit: false
};
export const [BoxOfficeFormProvider, useBoxOfficeFormContext, useBoxOfficeForm] =
    createFormContext<IBoxOffice>();
const BoxOffice = () => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<{ opened: boolean, record?: "", inEdit: boolean }>(initialModalState);
    const selectedEntity = useAppSelector(state => state.auth.selectedEntity);
    const [page, setPage] = useState(1);
    const [removeBoxOffice] = useRemoveBoxOfficeMutation();
    const page_size = 10;
    const { data = { results: [], count: 0 }, isFetching } = useGetBoxOfficesQuery({
        page,
        page_size: 10,
        entity: selectedEntity
    });

    const { data: boxOfficeRecord, isFetching: isFetchingRecord } = useReadBoxOfficeQuery({
            id: Number(modalState.record)
        },
        { skip: !modalState.record });

    useEffect(() => {
        if (selectedEntity) {
            form.setFieldValue("entity", selectedEntity);
        }
    }, [selectedEntity]);

    const form = useBoxOfficeForm({
        initialValues: {
            name: "",
            entity: selectedEntity,
            cashiers: []
        }
    });

    async function onDelete() {
        try {
            modalState.record && await removeBoxOffice({ id: modalState.record }).unwrap();
            notification({ type: "success", title: t("boxOffices.notifications.deleted") });
            setModalState(initialModalState);
        } catch (e) {
            notification({ type: "error", title: t("common.errorDefaultMessage") });
            console.log(e);
        }
    }

    useEffect(() => {
        if (modalState.record && modalState.inEdit) {
            form.setValues({
                ...boxOfficeRecord,
                entity: boxOfficeRecord.entity ? String(boxOfficeRecord.entity.id) : "",
                cashiers: boxOfficeRecord.cashiers ? boxOfficeRecord.cashiers.map((c: IUser) => c.id) : []
            });
        }
    }, [modalState.record, modalState.inEdit, boxOfficeRecord]);

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>Кассы</Text>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                columns={[
                    { label: t("boxOffices.form.name"), name: "name" },
                    { label: t("boxOffices.form.address"), name: "address_line" },
                    {
                        label: t("boxOffices.current_cashier"), name: "cashier", render: (value) => {
                            return value ? value?.first_name + " " + value?.last_name : "-";
                        }
                    },
                    {
                        name: "actions",
                        label: t("common.actions"),
                        type: "actions",
                        width: "50px",
                        render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"} onClick={() => setModalState({
                                    opened: true,
                                    record: record.id,
                                    inEdit: false
                                })}>
                                    <MoreHorizontal />
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => setModalState({ opened: true, inEdit: true })}
                createTitle={t("boxOffices.create")}
            />
            <CrudModal
                type={"drawer"}
                title={modalState.record ? boxOfficeRecord?.name : t("boxOffices.create")}
                opened={modalState.opened}
                formId={"boxOfficeForm"}
                isUpdate={Boolean(modalState.record)}
                onClose={() => {
                    setModalState(initialModalState);
                    form.reset();
                }}
                onDelete={onDelete}
                inEdit={modalState.inEdit}
                setEdit={(isEdit) => {
                    setModalState(prevState => {
                        return {
                            ...prevState,
                            inEdit: isEdit
                        };
                    });
                }}
            >
                <BoxOfficeFormProvider form={form}>
                    {modalState.inEdit ? (
                        <BoxOfficeForm
                            onClose={() => {
                                setModalState(prevState => {
                                    return {
                                        ...prevState,
                                        inEdit: !Boolean(prevState.record),
                                        opened: Boolean(prevState.record)
                                    };
                                });
                            }}
                            recordId={modalState.record}
                            record={boxOfficeRecord}
                        />
                    ) : (modalState.opened && !modalState.inEdit) && (
                        <BoxOfficeInfo record={boxOfficeRecord} isFetching={isFetchingRecord} />
                    )}
                </BoxOfficeFormProvider>
            </CrudModal>
        </PageContainer>
    );
};
export default BoxOffice;
