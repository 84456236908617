import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IZone } from "../../../api/zone";
import { IEvent } from "../../../api/event";
import dayjs from "dayjs";

interface SchemaState {
    loading: boolean;
    data: {
        data: {
            [key: string]: {
                id: number,
                zone: number | null,
                status?: "free" | "reserved" | "sold" | "disabled"
                rect: {
                    x: string,
                    y: string
                }
            }
        }
    };
    zones: IZone[];
    event: IEvent;
    selectedZone: IZone;
    totalSeats: number;
    updatedSeats: { seat: number, zone: number | null }[];
}

const initialState: SchemaState = {
    loading: false,
    data: { data: {} },
    zones: [],
    event: {},
    totalSeats: 0,
    selectedZone: {
        properties: {
            color: ""
        }
    },
    updatedSeats: []
};

export const pricesSlice = createSlice({
    name: "scheme",
    initialState,
    reducers: {
        setZones: (state, action) => {
            state.zones = action.payload;
        },
        setEvent: (state, action) => {
            state.event = action.payload;
        },
        toggleLoading: state => {
            state.loading = !state.loading;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setTotalSeats: (state, action) => {
            state.totalSeats = action.payload;
        },

        createZone: state => {
            const randomColor = "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
            state.zones.push({
                price: 200,
                properties: {
                    color: randomColor
                }
            });
        },
        setPrices: (state, action: PayloadAction<{ tmpSelection: string[] }>) => {
            const { tmpSelection } = action.payload;
            tmpSelection.forEach((seat) => {
                if ((seat && state.data.data[seat]) && (state.selectedZone.id || state.selectedZone.id === null)) {

                    //do not change the price on this statuses
                    if(state.data.data[seat].status === "reserved" || state.data.data[seat].status === "sold") return;

                    state.data.data[seat].zone = state.selectedZone.id;
                }
            });
        },
        onSeatClick: (state, action: PayloadAction<{ key: string, seatId: number }>) => {
            const { key, seatId } = action.payload;

            if (state.selectedZone.id || state.selectedZone.id === null) {
                const seatObj = state.data.data[key];
                const isSame = seatObj ? seatObj.zone === state.selectedZone.id : false;
                state.data.data[key].zone = isSame ? null : state.selectedZone.id || null;


                //set updated seat
                const seatIndex = state.updatedSeats.findIndex(f => f.seat === seatId);
                if (seatIndex !== -1) {
                    const seat = state.updatedSeats[seatIndex];

                    //update with zone null
                    if (seat.zone === state.selectedZone.id) {
                        state.updatedSeats[seatIndex] = { seat: state.updatedSeats[seatIndex].seat, zone: null };
                        return;
                    } else {
                        state.updatedSeats[seatIndex] = {
                            seat: seat.seat,
                            zone: isSame ? null : state.selectedZone.id
                        };
                        return;
                    }
                }
                //create new
                state.updatedSeats.push({
                    zone: isSame ? null : state.selectedZone.id,
                    seat: seatId
                });
            }
        },
        setSelectedZone: (state, action) => {
            state.selectedZone = action.payload;
        },
        onChangeZonePrice: (state, action) => {
            state.zones[action.payload.index].price = action.payload.price;
        },
        onChangeZoneColor: (state, action) => {
            state.zones[action.payload.index].properties.color = action.payload.color;
        },
        setUpdatedSeats: (state, action: PayloadAction<{ collectedSeats: { id: number, status: string }[] }>) => {
            action.payload.collectedSeats.forEach(seat => {
                if ((state.selectedZone.id || state.selectedZone.id === null) && seat) {
                    const seatIndex = state.updatedSeats.findIndex(f => f.seat === seat?.id);
                    //if new, push in stack
                    if(seat.status === 'sold' || seat.status === 'reserved') return;

                    if (seatIndex === -1) {
                        state.updatedSeats.push({
                            seat: seat.id,
                            zone: state.selectedZone.id
                        });
                        return;
                    }
                    //update seat by index
                    state.updatedSeats[seatIndex] = {
                        seat: seat.id,
                        zone: state.selectedZone.id
                    };
                }
            });

        },
        reset: () => initialState
    }
});
export const {
    setZones,
    setSelectedZone,
    createZone,
    onChangeZonePrice,
    onChangeZoneColor,
    setEvent,
    setUpdatedSeats,
    toggleLoading,
    setTotalSeats,
    setData,
    setPrices,
    onSeatClick,
    reset
} = pricesSlice.actions;
export default pricesSlice.reducer;
