import { useEffect, useState } from "react";
import { ActionIcon, Text } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { MoreHorizontal } from "lucide-react";

//Components
import { notification } from "../../../utils/notification";
import PageContainer from "../../../components/PageContainer";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import CrudModal from "../../../components/CrudModal/CrudModal";
//API
import { IScheme, useGetSchemesQuery, useReadSchemeQuery, useRemoveSchemeMutation } from "../../../api/scheme";
import { useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";

const initialModalState = {
    opened: false,
    inEdit: false
};
export const [SchemeFormProvider, useSchemeFormContext, useSchemeForm] =
    createFormContext<IScheme>();
const Scheme = () => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<{ opened: boolean, record?: "", inEdit: boolean }>(initialModalState);
    const [page, setPage] = useState<number>(1);
    const [removeScheme] = useRemoveSchemeMutation();
    const selectedEntity = useAppSelector((state) => state.auth.selectedEntity);
    const page_size = 10;
    const { data = { results: [], count: 0 }, isFetching } = useGetSchemesQuery({
        page,
        page_size: 10,
        entity: selectedEntity
    });

    const { data: schemeRecord, isFetching: isFetchingRecord } = useReadSchemeQuery({
            id: Number(modalState.record)
        },
        { skip: !modalState.record });

    const form = useSchemeForm({
        initialValues: {
            name: ""
        }
    });
    useEffect(() => {
        if (modalState.record && modalState.inEdit) {
            // form.setValues(schemeRecord);
        }
    }, [modalState.record, modalState.inEdit, schemeRecord]);

    async function onDelete() {
        try {
            modalState.record && await removeScheme({ id: modalState.record }).unwrap();
            notification({ type: "success", title: t('scheme.notifications.deleted')});
            setModalState(initialModalState);
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>{t("scheme.scheme")}</Text>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                columns={[
                    { label: t('scheme.form.name'), name: "name" },
                    {
                        name: "actions", label: t('common.actions'), type: "actions", width: "50px", render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"} onClick={() => setModalState({
                                    opened: true,
                                    record: record.id,
                                    inEdit: false
                                })}>
                                    <MoreHorizontal />
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => setModalState({ opened: true, inEdit: true })}
                createTitle={t('scheme.create')}
            />
            <CrudModal
                type={"drawer"}
                title={modalState.record ? schemeRecord?.name : t('scheme.create')}
                opened={modalState.opened}
                formId={"SchemeForm"}
                isUpdate={Boolean(modalState.record)}
                onClose={() => {
                    setModalState(initialModalState);
                    form.reset();
                }}
                onDelete={onDelete}
                inEdit={modalState.inEdit}
                setEdit={(isEdit) => {
                    setModalState(prevState => {
                        return {
                            ...prevState,
                            inEdit: isEdit
                        };
                    });
                }}
            >
                <></>
            </CrudModal>
        </PageContainer>
    );
};

export default Scheme;
