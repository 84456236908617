import classes from "./Seat.module.css";
import clsx from "clsx";
import React, {useRef, useState} from "react";
import {useEventListener} from "usehooks-ts";
import {Shield} from "lucide-react";
import {useTranslation} from "react-i18next";

export interface ISeatProps {
    x: string;
    y: string;
    id: number;
    ticket?: number;
    fill?: string;
    isSelected?: boolean,
    seatKey: string,
    zone: number | null,
    price?: number;
    onClick?: (id: number, key: string, zone?: number | null, status?: "free" | "reserved" | "sold" | "disabled", ticket?: number, isShortCut?: boolean) => void,
    status?: "free" | "reserved" | "sold" | "disabled",
    showOnly?: "reserved" | "free" | "sold",
    widgetMode?: boolean,
    type: "price" | "sales"
}

const radiusByStatus = {
    "sold": "3",
    "processing": "3",
    "free": "6",
    "reserved": "3",
    "disabled": "3",
    "showOnly": "3"
};

const Seat = (props: ISeatProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation();
    const {
        x,
        y,
        id,
        seatKey,
        fill,
        zone,
        ticket,
        isSelected,
        onClick,
        status,
        price,
        showOnly,
        widgetMode,
        type
    } = props;
    const circleRef = useRef<any | null>(null);

    async function handleClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        if (onClick) {
            try {
                setIsLoading(true)
                await onClick(id, seatKey, zone, status, ticket, Boolean(e.ctrlKey && e.shiftKey));
                setIsLoading(false)
            } catch (e) {
                console.log(e)
                setIsLoading(false)
            }
        }
    }

    function mouseOver(e: MouseEvent) {
        if (widgetMode) return;
        const d = circleRef.current;
        const target = e.target as SVGElement;
        if (d && e.target !== null) {
            const tooltip = document.getElementById("seatTooltip");
            const zoneText = document.getElementById("zone");
            const seatText = document.getElementById("seat");
            const priceText = document.getElementById("price");

            if (target.getAttribute("cx") === null) return;

            if (tooltip && zoneText && seatText && priceText) {
                //TODO WHEN we will have more than one schema, get max x,y from schema attributes
                const minX = 50;
                const maxX = 940;
                const maxY = 81;

                let targetX = Number(target.getAttribute("cx"));
                let targetY = Number(target.getAttribute("cy"));

                //offset for min and max X
                let x = targetX < minX ? minX : targetX > maxX ? maxX : targetX;

                //offset for max Y
                let y = targetY > maxY ? targetY - maxY : targetY;

                tooltip.setAttribute("x", String(x - 50));
                tooltip.setAttribute("y", String(y + 15));
                tooltip.setAttribute("width", "110");
                tooltip.setAttribute("height", "55");

                zoneText.setAttribute("x", String(x - 38));
                seatText.setAttribute("x", String(x - 38));
                priceText.setAttribute("x", String(x - 38));

                zoneText.setAttribute("y", String(y + 30));
                seatText.setAttribute("y", String(y + 45));
                priceText.setAttribute("y", String(y + 60));

                const split = seatKey.split(":");
                const label = split[0];
                const rowN = split[1];
                const seatN = split[2];
                zoneText.textContent = `${t(`sales.${label}`)}, `;
                seatText.textContent = `${t(`sales.row`)} ${rowN}, ${t(`sales.seat`)} ${seatN},`;
                priceText.textContent = price ? `${price} MDL` : "";
            }
        }
    }

    function mouseOut() {
        if (widgetMode) return;
        const tooltip = document.getElementById("seatTooltip");
        const zoneText = document.getElementById("zone");
        const seatText = document.getElementById("seat");
        const priceText = document.getElementById("price");
        if (tooltip && zoneText && seatText && priceText) {
            tooltip.setAttribute("width", "0");
            tooltip.setAttribute("height", "0");
            zoneText.textContent = "";
            seatText.textContent = "";
            priceText.textContent = "";
        }
    }

    useEventListener("mouseover", mouseOver, circleRef);
    useEventListener("mouseout", mouseOut, circleRef);
    // console.log(status, isSelected)
    return (
        <svg onClick={handleClick} style={{cursor: "pointer"}} className={classes.seatContainer}>
            <circle
                ref={circleRef}
                className={!showOnly ? clsx({
                    [classes.seat_circle]: status === "free" && !isSelected,
                    [classes.seat_circle_sold]: status === "sold",
                    [classes.seat_circle_without_price]: type === "sales" && !zone,
                    [classes.seat_circle_reserved]: !isSelected && status === "reserved",
                    [classes.seat_circle_selected]: isSelected,
                    [classes.seat_circle_loading]: isLoading
                }) : clsx({
                    [classes.seat_circle]: status === showOnly,
                    [classes.seat_circle_sold]: status !== showOnly
                })}
                id={seatKey}
                cx={x}
                cy={y}
                r={showOnly ?
                    showOnly === status ? "6" : "3" : //show only radius
                    !isSelected ?
                        !zone && type === "sales" ? "3" : //show small circles in sales schema for seats without price
                            radiusByStatus[status as keyof typeof radiusByStatus] : //show circles by specified radius type
                        isSelected ? "9" : "6" //radius for selected seats
                }
                fill={fill}
                stroke={fill}
            />
            {isSelected && <Check x={x} y={y} isColored={true} color={fill}/>}
            {type === "sales" && !zone ? (<></>) : (
                !widgetMode && (showOnly ? (showOnly ? showOnly === status && !isSelected : true) : status === "free" && !isSelected) && (
                    <text
                        className={classes.seatText}
                        x={x} y={y}
                        textAnchor="middle"
                        alignmentBaseline="central"
                        fill="#E4E6ED"
                        fontSize="8"
                    >
                        {seatKey.split(":")[2]}
                    </text>
                )
            )}

        </svg>
    );
};
export default Seat;


const Check = ({x, y, isColored}: { x: string, y: string, isColored: boolean, color?: string }) => {
    return <svg height="10px" width="10px"
                style={{
                    pointerEvents: "none"
                }}
                viewBox="0 0 17.837 17.837"
                x={Number(x) - 5}
                y={Number(y) - 5}
    >
        <g>
            <path style={{fill: isColored ? "green" : "white"}} d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
		c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
		L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"/>
        </g>
    </svg>;
};

const Reserved = ({x, y, isColored}: { x: string, y: string, isColored: boolean }) => {
    return <svg height="10px" width="10px"
                style={{
                    pointerEvents: "none"
                }}
                viewBox="0 0 17.837 17.837"
                x={Number(x) - 4.5}
                y={Number(y) - 4.3}
    >
        <g>
            <Shield size={16} color={"gray"}/>
        </g>
    </svg>;
};
