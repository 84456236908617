import { api } from "../app/api"

export interface IUserGroup {
    id: string
    name: string
    label: string
}

export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserGroups: build.query<IUserGroup[], any>({
            query: () => ({
                url: `/user/admin/groups/`,
            }),
            providesTags: (result, error, tag) => [{ type: "UserGroup", tag }],
        })
    }),
})

export const {
    useGetUserGroupsQuery,
} = userApi
