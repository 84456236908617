import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "./App.css";

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { useAppDispatch, useAppSelector } from "./app/hooks";

import { useRoutes } from "./routes";
import Layout from "./components/Layout";
import { useGetUserDetailsQuery } from "./pages/authorization/authService";
import { setLoadingApp } from "./app/app.slice";
import { clearAuthorization } from "./pages/authorization/authorization.slice";

function App() {
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    const roles = useAppSelector(state => state.auth.user.role)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const routes = useRoutes(Boolean(accessToken), roles);
    const { data, isFetching, isError } = useGetUserDetailsQuery("", {
        pollingInterval: accessToken ? 900000 : undefined,
        skip: !accessToken
    });

    useEffect(() => {
        const isWidgetRoute = location.pathname.startsWith("/buy-widget");

        if(isWidgetRoute) return;

        if (!accessToken) {
            navigate("/");
            return;
        }
        if (isFetching) {
            dispatch(setLoadingApp(isFetching));
            return;
        }

        if (!data || isError) {
            console.log(data, isError);
            // dispatch(clearAuthorization());
            // navigate("/");
        }

        dispatch(setLoadingApp(isFetching));
    }, [data, isFetching, isError, dispatch, navigate, accessToken]);

    return (
        <MantineProvider defaultColorScheme={"dark"}
                         theme={{
                             defaultRadius: "lg",
                             primaryColor: "teal",
                             components: {
                                 Loader: {
                                     defaultProps: {
                                         type: 'dots',
                                         color: 'orange'
                                     }
                                 }
                             }
                         }}
        >
            <Notifications position={"bottom-left"} zIndex={9999} />
            <Layout>{routes}</Layout>
        </MantineProvider>
    );
}

export default App;
