import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

type TInitialState = {
    selectedEvent?: number
    selectedCashier?: number,
    byPrices?: boolean,
    byEvents?: boolean,
    byCashier?: boolean,
    datesRange: [string | null, string | null],
    date: string | null
}

const initialState: TInitialState = {
    datesRange: [dayjs(new Date()).toISOString(), dayjs(new Date()).toISOString()],
    byPrices: true,
    byEvents: true,
    byCashier: true,
    date: dayjs(new Date()).toISOString()
};

export const reportsSlice = createSlice({
    name: "scheme",
    initialState,
    reducers: {
        setSelectedEvent: (state, action: PayloadAction<number>) => {
            state.selectedEvent = action.payload;
        },
        setSelectedCashier: (state, action: PayloadAction<number>) => {
            state.selectedCashier = action.payload;
        },
        setDatesRange: (state, action: PayloadAction<[string | null, string | null]>) => {
            state.datesRange = action.payload;
        },
        onChangeByPrices: (state, action: PayloadAction<boolean>) => {
            state.byPrices = action.payload;
        },
        onChangeByEvents: (state, action: PayloadAction<boolean>) => {
            state.byEvents = action.payload;
        },
        onChangeByCashier: (state, action: PayloadAction<boolean>) => {
            state.byCashier = action.payload;
        },
        onChangeDate: (state, action: PayloadAction<string | null>) => {
            state.date = action.payload;
        }
    }
});

export const {
    setSelectedEvent,
    setSelectedCashier,
    setDatesRange,
    onChangeByPrices,
    onChangeByEvents,
    onChangeByCashier,
    onChangeDate
} = reportsSlice.actions;

export default reportsSlice.reducer;
