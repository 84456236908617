import { api } from "../app/api";
import { IUser } from "./user";
import { IEntity } from "./entity";

export interface IInvoice {
    id: number;
    customer: IUser;
    entity: IEntity,
    description: string;
    amount: string;
    payment_type: string;
    event: number;
    timestamp: string,
    status: string,
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IInvoice[];
}

interface IQueryParams {
    event?: number;
    customer?: number;
    entity?: number;
    booking?: number;
    ordering?: string;
    page?: number;
    page_size?: number;
}

export const eventApi = api.injectEndpoints({
    endpoints: (build) => ({
        getInvoices: build.query<IQueryResponse, IQueryParams>({
            query: (params) => ({
                url: `/sales/admin/invoices/`,
                params: {
                    event: params.event,
                    customer: params.customer,
                    entity: params.entity,
                    booking: params.booking,
                    page: params.page,
                    page_size: params.page_size,
                    ordering: params.ordering
                }
            }),
            providesTags: (result, error, tag) => [{ type: "Invoice", tag }]
        }),
        invoiceBuy: build.mutation<any, {
            items: {seat: number}[],
            event: number,
            description?: string,
            customer?: string,
            entity?: number,
            booking?: number
        }>({
            query: (payload) => ({
                url: "/sales/admin/invoices/buy/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Invoice", "Scheme", "Booking"]
        }),
        invoiceRefund: build.mutation<any, {
            items: { ticket: number }[],
            event: number,
        }>({
            query: (payload) => ({
                url: "/sales/admin/invoices/refund/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Invoice", "Scheme", "Booking"]
        }),
    })
});

export const {
    useGetInvoicesQuery,
    useLazyGetInvoicesQuery,
    useInvoiceBuyMutation,
    useInvoiceRefundMutation
} = eventApi;
