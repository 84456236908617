import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../pages/authorization/authorization.slice";
import schemaReducer from "../pages/events/schema/prices.slice";
import salesReducer from "../pages/sales/sales.slice";
import reportsReducer from "../pages/reports/reports.slice"
import homeReducer from "../pages/home/home.slice"
import appReducer from "./app.slice";
import { api } from "./api";

export const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        schema: schemaReducer,
        sales: salesReducer,
        reports: reportsReducer,
        home: homeReducer,
        [api.reducerPath]: api.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware)
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
