import PageContainer from "../../components/PageContainer";
import { Text } from "@mantine/core";

const Reports = () => {
    return (
        <PageContainer>
            <Text mt={0} mb={30} size={'xl'}>Отчеты</Text>
        </PageContainer>
    );
};
export default Reports;
