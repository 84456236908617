import { api } from "../app/api";
import { IUser } from "./user";

export interface IEntity {
    id?: number;
    name?: string;
    country?: string;
    county?: string;
    city?: string;
    postcode?: string;
    street?: string;
    street_number?: string;
    block?: string;

    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

export interface IEntityResponse {
    count: number;
    next: any;
    previous: any;
    results: IEntity[];
}


export const entityApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEntities: build.query<IEntityResponse, {
            name?: string,
            ordering?: string,
            page?: number,
            page_size?: number
        }>({
            query: (params) => ({
                url: `/entity/admin/entities/`,
                params: {
                    page: params.page,
                    page_size: params.page_size
                }
            }),
            providesTags: (result, error, tag) => [{ type: "Entity", tag }]
        }),
        createEntity: build.mutation<any, IEntity>({
            query: (payload) => ({
                url: "/entity/admin/entities/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "Entity" }]
        }),
        updateEntity: build.mutation<any, IEntity & { id: number }>({
            query: (payload) => ({
                url: `/entity/admin/entities/${payload.id}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: [{ type: "Entity" }]
        }),
        readEntity: build.query<any, { id: number }>({
            query: (params) => ({
                url: `/entity/admin/entities/${params.id}/`,
                method: "GET"
            }),
            providesTags: (result, error, tag) => [{ type: "Entity", tag }],
        }),
        removeEntity: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/entity/admin/entities/${payload.id}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "Entity" }]
        })
    })
});

export const {
    useCreateEntityMutation,
    useUpdateEntityMutation,
    useRemoveEntityMutation,
    useReadEntityQuery,
    useGetEntitiesQuery,
    useLazyReadEntityQuery
} = entityApi;
