import { useTranslation } from "react-i18next";
import {
    Combobox,
    InputBase,
    Input,
    useCombobox,
    ScrollArea,
    Flex,
    Box,
    useMantineColorScheme
} from "@mantine/core";
import dayjs from "dayjs";
import classes from "./EventInputSelect.module.css";

type IProps = {
    value: string | null
    onChange: (v: string) => void
    data: any[]
}

const EventInputSelect = ({value, onChange, data}: IProps) => {
    const { colorScheme } = useMantineColorScheme();
    const { t } = useTranslation();

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption()
    });

    const selectedObject = data?.find(e => e.id === Number(value));
    const options = data?.map((item) => (
        <Combobox.Option value={String(item.id)} key={item.id}
                         style={{
                             borderRadius: 0,
                             borderBottom: colorScheme === 'dark' ? "1px solid #141517" : "1px solid #DEE2E6",
                             background: selectedObject?.id === item.id ?
                                 colorScheme === 'dark' ? "#141517" : "#F8F9FA" : ""
                         }}>
            <Flex justify={"space-between"}>
                <Box style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }} w={"70%"}>{item.name}</Box>
                <Box style={{ whiteSpace: "nowrap" }}>{dayjs(item.start).format("DD-MM-YYYY HH:mm")}</Box>
            </Flex>
        </Combobox.Option>
    ));
    return (
        <Combobox
            store={combobox}
            onOptionSubmit={(val) => {
                onChange(val);
                combobox.closeDropdown();
            }}
            position={"bottom-end"}
        >
            <Combobox.Target>
                <InputBase
                    component="button"
                    pointer
                    rightSectionPointerEvents="none"
                    w={220}
                    classNames={{
                        input: classes.input
                    }}
                    rightSection={<Combobox.Chevron />}
                    onClick={() => combobox.toggleDropdown()}
                >
                    {selectedObject?.name || <Input.Placeholder>{t("events.event")}</Input.Placeholder>}
                </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown classNames={{ dropdown: classes.dropdown }}>
                <Combobox.Options>
                    <ScrollArea.Autosize mah={200} type="scroll">
                        {options}
                    </ScrollArea.Autosize>
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};

export default EventInputSelect;
