import { useGetBoxOfficesQuery } from "../../api/box-office";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Select } from "@mantine/core";
import { useConfirmationModal } from "../../hooks/useConfirmationModal";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../app/api";
import { activateBoxOffice } from "../../pages/sales/sales.thunks";

const BoxOfficeChanger = () => {
    const { t } = useTranslation();
    const { data = { results: [] } } = useGetBoxOfficesQuery({
        page: 1,
        page_size: 10,
        accessible: true
    }, { refetchOnMountOrArgChange: true });
    const [ConfirmModal, confirm] = useConfirmationModal({ title: "Касса занята, продолжить ?" });
    const activeBoxOffice = useAppSelector(state => state.sales.activeBoxOffice);
    const userId = useAppSelector(state => state.auth.user.id);
    const dispatch = useAppDispatch();

    async function onChangeBoxOffice(v: string) {

        if (!v || v === activeBoxOffice) return;

        try {
            const selectedOffice = data.results.find(f => f.id === Number(v)) || {};

            const isConfirmed = selectedOffice.token ?
                selectedOffice.cashier?.id === userId ? true : await confirm() :
                true;

            isConfirmed && dispatch(activateBoxOffice({ boxOfficeId: Number(v) }));

        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }
    }

    return (
        <>
            <Select
                mr={10}
                allowDeselect={false}
                placeholder={t("header.selectCashBox")}
                value={activeBoxOffice}
                onChange={onChangeBoxOffice}
                data={data.results.map(v => {
                    return {
                        label: v.name || String(v.id),
                        value: String(v.id)
                    };
                })}
            />
            <ConfirmModal />
        </>
    );
};

export default BoxOfficeChanger;
