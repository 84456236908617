import { api } from "../app/api";
import { IUserProfile } from "../pages/authorization/authorization.slice";

interface IUserGroups {
    name?: string;
}

export interface IUser {
    id?: string;
    set_groups?: IUserGroups[];
    sex?: "male" | "female";
    email: string;
    first_name?: string;
    last_name?: string;
    phone: string;
    is_active: boolean;
    groups?: {name: string}[]
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}
export interface IUserShort {
    id: number,
    email?: string,
    first_name?: string,
    last_name?: string,
    phone?: string

}
interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IUser[];
}

interface IQueryParams {
    email?: number;
    group?: string[];
    ordering?: string;
    is_staff?: boolean;
    page?: number;
    page_size?: number;
}

export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query<IQueryResponse, IQueryParams>({
            query: (params) => ({
                url: `/user/admin/users/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    group: params.group,
                    is_staff: params.is_staff
                }
            }),
            providesTags: (result, error, tag) => [{ type: "User", tag }]
        }),
        createUser: build.mutation<any, any>({
            query: (payload) => ({
                url: "/user/admin/users/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "User" }]
        }),
        updateUser: build.mutation<any, any>({
            query: (payload) => ({
                url: `/user/admin/users/${payload.id}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: [{ type: "User" }]
        }),
        readUser: build.query<any, any>({
            query: (params) => ({
                url: `/user/admin/users/${params.id}`
            }),
            providesTags: (result, error, tag) => [{ type: "User", tag }],
            transformResponse: (response: IUser & { groups: { name: string }[] }) => ({
                ...response,
                set_groups: response.groups ? response.groups.map(gr => gr.name) : []
            })
        }),
        setPassword: build.mutation<any, { id: string, password: string, passwordRepeat: string }>({
            query: (payload) => ({
                url: `/user/admin/users/${payload.id}/set-password/`,
                method: "POST",
                body: {
                    password: payload.password,
                    confirm_password: payload.passwordRepeat
                }
            })
        }),
        changePassword: build.mutation<any, { password: string, passwordRepeat: string }>({
            query: (payload) => ({
                url: `/user/change-password/`,
                method: "POST",
                body: {
                    password: payload.password,
                    confirm_password: payload.passwordRepeat
                }
            })
        }),
        updateProfile: build.mutation<any, IUserProfile>({
            query: (payload) => ({
                url: `/user/profile/`,
                method: "PATCH",
                body: payload
            })
        }),
        removeUser: build.mutation<any, any>({
            query: (payload) => ({
                url: `/user/admin/users/${payload}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "User" }]
        })
    })
});

export const {
    useCreateUserMutation,
    useUpdateUserMutation,
    useRemoveUserMutation,
    useReadUserQuery,
    useGetUsersQuery,
    useSetPasswordMutation,
    useChangePasswordMutation,
    useUpdateProfileMutation
} = userApi;
