import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import { createFormContext } from "@mantine/form";
import { IUser, useGetUsersQuery, useReadUserQuery, useRemoveUserMutation } from "../../api/user";
import { notification } from "../../utils/notification";
import { MoreHorizontal } from "lucide-react";
import DefaultTable from "../../components/DefaultTable/DefaultTable";
import UsersForm from "./UsersForm";
import CrudModal from "../../components/CrudModal/CrudModal";
import { ActionIcon, Text, Skeleton, Flex } from "@mantine/core";
import UsersInfo from "./UsersInfo";
import { useTranslation } from "react-i18next";
import classes from "../events/Events.module.css";

const initialModalState = {
    opened: false,
    inEdit: false
};
export const [UsersFormProvider, useUsersFormContext, useUsersForm] =
    createFormContext<IUser>();
const Users = () => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<{ opened: boolean, record?: "", inEdit: boolean }>(initialModalState);
    const [page, setPage] = useState(1);
    const [removeUser] = useRemoveUserMutation();

    const page_size = 10;
    const { data = { results: [], count: 0 }, isFetching } = useGetUsersQuery({
        page,
        page_size: 10,
        is_staff: true
    });

    const { data: userRecord, isFetching: isFetchingRecord } = useReadUserQuery({
            id: modalState.record
        },
        { skip: !modalState.record });

    const form = useUsersForm({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            set_groups: [],
            phone: "",
            is_active: false
        }
    });

    async function onDelete() {
        try {
            await removeUser(modalState.record).unwrap();
            notification({ type: "success", title: t("users.notifications.deleted") });
            setModalState(initialModalState);
        } catch (e) {
            notification({ type: "error", title: t("common.errorDefaultMessage") });
            console.log(e);
        }
    }

    useEffect(() => {
        if (modalState.record && modalState.inEdit) {
            form.setValues({
                id: userRecord.id,
                first_name: userRecord.first_name,
                last_name: userRecord.last_name,
                email: userRecord.email,
                phone: userRecord.phone,
                is_active: userRecord.is_active,
                sex: userRecord.sex,
                set_groups: userRecord.groups.map((f: { name: string }) => f.name)
            });
        }
    }, [modalState.record, modalState.inEdit, userRecord]);

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>{t("users.users")}</Text>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                columns={[
                    {
                        label: t("users.form.firstName"), name: "first_name", render: (_, record) => {
                            return (
                                <Flex align={"center"}>
                                    <Skeleton circle height={10} mr={8}
                                              animate={false}
                                              classNames={{
                                                  root: !record.is_active ?
                                                      classes.eventStatusCircleBlocked :
                                                      classes.eventStatusCircleRunning
                                              }} />
                                    {record.first_name + " " + record.last_name}
                                </Flex>
                            );
                        }
                    },
                    // { label: t("users.form.lastName"), name: "last_name" },
                    { label: "Email", name: "email" },
                    { label: t("users.form.phone"), name: "phone" },
                    {
                        label: t("users.form.groups"), name: "groups", render: (record) => {
                            return record.map((v: { name: string }) => v.name).join(",");
                        }
                    },
                    {
                        name: "actions",
                        label: t("common.actions"),
                        type: "actions",
                        width: "50px",
                        render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"} onClick={() => setModalState({
                                    opened: true,
                                    record: record.id,
                                    inEdit: false
                                })}>
                                    <MoreHorizontal />
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => setModalState({ opened: true, inEdit: true })}
                createTitle={t("users.create")}
            />
            <CrudModal
                type={"drawer"}
                title={t("users.user")}
                opened={modalState.opened}
                formId={"usersForm"}
                isUpdate={Boolean(modalState.record)}
                onClose={() => {
                    setModalState(initialModalState);
                    form.reset();
                }}
                onDelete={onDelete}
                inEdit={modalState.inEdit}
                setEdit={(isEdit) => {
                    setModalState(prevState => {
                        return {
                            ...prevState,
                            inEdit: isEdit
                        };
                    });
                }}
            >
                <UsersFormProvider form={form}>
                    {modalState.opened && (
                        modalState.inEdit ? (
                            <UsersForm
                                onClose={() => {
                                    setModalState(prevState => {
                                        return {
                                            ...prevState,
                                            inEdit: !Boolean(prevState.record),
                                            opened: Boolean(prevState.record)
                                        };
                                    });
                                }}
                                recordId={modalState.record}
                            />
                        ) : (
                            <UsersInfo record={userRecord} isFetching={isFetchingRecord} />
                        )
                    )}


                </UsersFormProvider>
            </CrudModal>
        </PageContainer>
    );
};

export default Users;
