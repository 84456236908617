import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {HTTP} from "../../utils/http";
import {IEntityResponse} from "../../api/entity";

interface LocalStorageUserInfo {
    id: string;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    selectedEntity: string | null;
    phone: string | null;
    groups_list: string[] | null,
    role: string | null,
}

const localStorageUserInfo: LocalStorageUserInfo = JSON.parse(
    localStorage.getItem("userInfo") || "{}"
);

const userToken = localStorage.getItem("userToken") || null;

const accessToken = localStorage.getItem("accessToken") || null;
const refreshToken = localStorage.getItem("refreshToken") || null;

interface AuthResponse {
    sessionId: string
    csrftoken: string,
    profile: {
        email: string,
        first_name: string,
        last_name: string,
        phone: string,
        sex: string,
        id: number
    }
}

interface AuthResponseJWT {
    access: string,
    refresh: string
}

export interface IUserProfile {
    id?: number | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    role?: string[] | null,
    groups_list?: string[] | null
}

interface AuthState {
    user: IUserProfile;
    userToken: null | string;
    refreshToken: null | string;
    accessToken: null | string;
    selectedEntity?: number;
    isAuth: boolean;
    loading: boolean;
    error: null | string;
}

export interface SignInPayload {
    username: string;
    password: string;
}

const initialState: AuthState = {
    user: {
        id: Number(localStorageUserInfo.id) || null,
        first_name: localStorageUserInfo.first_name,
        last_name: localStorageUserInfo.last_name,
        email: localStorageUserInfo.email,
        phone: localStorageUserInfo.phone,
        groups_list: localStorageUserInfo.groups_list,
        role: localStorageUserInfo.groups_list ? localStorageUserInfo.groups_list : null,
    },
    selectedEntity: Number(localStorageUserInfo.selectedEntity),
    userToken,
    refreshToken,
    accessToken,
    isAuth: false,
    loading: false,
    error: null
};

export const signInAsync = createAsyncThunk<
    any,
    SignInPayload,
    { rejectValue: string }
>("auth/signIn", async (payload, thunkApi) => {
    try {
        const result = await HTTP.post<AuthResponse>(
            "/user/login/",
            JSON.stringify(payload)
        );
        const profile = await HTTP.get("/user/profile");
        const entities = await HTTP.get<IEntityResponse>("/entity/admin/entities/");

        //set user token
        localStorage.setItem("userToken", result.data.csrftoken);

        //load previously defined selectedEntity
        const localStorageInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
        const selectedEntity = localStorageInfo.selectedEntity ? localStorageInfo.selectedEntity : entities.data.results?.[0].id;

        //save in localstorage all user info
        localStorage.setItem(
            "userInfo",
            JSON.stringify({
                id: profile.data.id,
                first_name: profile.data.first_name,
                last_name: profile.data.last_name,
                email: profile.data.email,
                phone: profile.data.phone,
                groups_list: profile.data.groups_list,
                role: profile.data.groups_list[0],
                selectedEntity
            })
        );
        return {
            ...result.data,
            profile: profile.data,
            selectedEntity
        };
    } catch (e) {
        if (axios.isAxiosError(e)) {
            if (e.response) {
                return thunkApi.rejectWithValue(e.response.data.message);
            }
        }
        return thunkApi.rejectWithValue(e as string);
    }
});


export const signInAsyncJWT = createAsyncThunk<
    any,
    SignInPayload,
    { rejectValue: string }
>("auth/signInJWT", async (payload, thunkApi) => {
    const result = await HTTP.post<AuthResponseJWT>(
        "/user/token/",
        {
            email: payload.username,
            password: payload.password
        }
    );

    //set jwt tokens
    localStorage.setItem("accessToken", result.data.access);
    localStorage.setItem("refreshToken", result.data.refresh);


    const profile = await HTTP.get("/user/profile/");
    const entities = await HTTP.get<IEntityResponse>("/entity/admin/entities/");

    //load previously defined selectedEntity
    const localStorageInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
    const selectedEntity = localStorageInfo.selectedEntity ? localStorageInfo.selectedEntity : entities.data.results?.[0].id;

    //save in localstorage all user info
    localStorage.setItem(
        "userInfo",
        JSON.stringify({
            id: profile.data.id,
            first_name: profile.data.first_name,
            last_name: profile.data.last_name,
            email: profile.data.email,
            phone: profile.data.phone,
            groups_list: profile.data.groups_list,
            role: profile.data.groups_list[0],
            selectedEntity
        })
    );
    return {
        ...result.data,
        profile: profile.data,
        selectedEntity
    };
})

export const signOutAsync = createAsyncThunk(
    "auth/signOut",
    async (payload, thunkApi) => {
        try {
            await HTTP.post('/user/logout/')
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response) {
                    return thunkApi.rejectWithValue(e.response.data.message as string);
                }
            }
            return thunkApi.rejectWithValue(e as string);
        }
    }
);

export const authorizationSlice = createSlice({
    name: "authorization",
    initialState,
    reducers: {
        clearAuthorization: (state) => {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            state.accessToken = null;
            state.refreshToken = null;
        },
        updateAccessToken: (state, action: PayloadAction<{access: string, refresh: string}>) => {
            state.accessToken = action.payload.access
            localStorage.setItem("accessToken", action.payload.access)
        },
        setEntity: (state, action) => {
            localStorage.setItem(
                "userInfo",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("userInfo") || "{}"),
                    selectedEntity: action.payload
                })
            );
            state.selectedEntity = action.payload;
        },
        updateProfile: (state, action) => {
            state.user = {
                ...state.user,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                phone: action.payload.phone
            };
            const localStorageInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
            localStorage.setItem(
                "userInfo",
                JSON.stringify({
                    ...localStorageInfo,
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    phone: action.payload.phone
                })
            );
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signInAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInAsync.fulfilled, (state, {payload}) => {
                state.userToken = payload.csrftoken;
                state.user = {
                    id: payload.profile.id,
                    first_name: payload.profile.first_name,
                    last_name: payload.profile.last_name,
                    email: payload.profile.email,
                    phone: payload.profile.phone,
                    groups_list: payload.profile.groups_list,
                    role: payload.profile.groups_list
                };
                state.selectedEntity = payload.selectedEntity;
                state.error = null;
                state.loading = false;
                state.isAuth = true;
            })
            .addCase(signInAsync.rejected, state => {
                state.loading = false;
                state.error = "";
                state.isAuth = false;
                showNotification({
                    color: "red",
                    title: "Ошибка авторизации",
                    message: ""
                });
            });
        builder
            .addCase(signInAsyncJWT.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInAsyncJWT.fulfilled, (state, {payload}) => {
                console.log(payload)
                state.accessToken = payload.access;
                state.refreshToken = payload.refresh;
                state.user = {
                    id: payload.profile.id,
                    first_name: payload.profile.first_name,
                    last_name: payload.profile.last_name,
                    email: payload.profile.email,
                    phone: payload.profile.phone,
                    groups_list: payload.profile.groups_list,
                    role: payload.profile.groups_list
                };
                state.selectedEntity = payload.selectedEntity;
                state.error = null;
                state.loading = false;
                state.isAuth = true;
            })
            .addCase(signInAsyncJWT.rejected, state => {
                state.loading = false;
                state.error = "";
                state.isAuth = false;
                showNotification({
                    color: "red",
                    title: "Ошибка авторизации",
                    message: ""
                });
            });
        builder
            .addCase(signOutAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(signOutAsync.fulfilled, (state) => {
                state.loading = false;
                state.user = initialState.user;
                state.isAuth = false;
                state.userToken = null;
                state.refreshToken = null;
                state.accessToken = null
                localStorage.removeItem("userToken");
                localStorage.removeItem("accessToken")
                localStorage.removeItem("refreshToken")
            })
            .addCase(signOutAsync.rejected, (state) => {
                state.loading = false;
                showNotification({
                    color: "red",
                    title: "Ошибка",
                    message: "Server error"
                });
            });
    }
});
export default authorizationSlice.reducer;

export const {clearAuthorization, setEntity, updateProfile,updateAccessToken} = authorizationSlice.actions;
