import {
    TextInput,
    PasswordInput,
    Paper,
    Title,
    Text,
    Container,
    Button, Box, Center, Flex
} from "@mantine/core";
import classes from "./Login.module.css";
import {useForm} from "@mantine/form";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {signInAsync, signInAsyncJWT, SignInPayload} from "./authorization.slice";
import {useNavigate} from "react-router-dom";
import {ThemeChanger} from "../../components/Header/Header";
import {useTranslation} from "react-i18next";
import {setUserLanguage} from "../../app/app.slice";
import {LockIcon} from "lucide-react";

const Login = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {loading} = useAppSelector((state) => state.auth);
    const form = useForm({
        initialValues: {
            username: "",
            password: ""
        }
    });

    async function onSubmit(values: SignInPayload) {
        try {
            const res = await dispatch(signInAsyncJWT(values));
            const role = res.payload.profile.groups_list[0];
            if (role === "cashiers") {
                navigate("/events");
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Container size={620} my={80} pos={"relative"}>
            <Box pos={"absolute"} right={20} top={15}>
                <ThemeChanger/>
            </Box>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Container mt={10} mb={10}>
                    <Title ta="center" className={classes.title}>
                        {t("login.enter_account")}
                    </Title>
                    <Center mt={20} c={'red.5'}>
                        <LockIcon/>
                    </Center>
                </Container>
                <form
                    onSubmit={form.onSubmit((values) => {
                        onSubmit(values);
                    })}
                >
                    <TextInput
                        variant={"default"}
                        label={t("login.email")}
                        placeholder="you@ticket.dev"
                        required
                        {...form.getInputProps("username")}
                    />
                    <PasswordInput
                        label={t("login.password")}
                        required
                        mt="md"
                        {...form.getInputProps("password")}
                    />
                    <LngSwitcher/>
                    <Button fullWidth mt="20" type={"submit"} loading={loading}>
                        {t("login.enter")}
                    </Button>
                </form>
            </Paper>
        </Container>
    );
};
export default Login;

const LngSwitcher = () => {
    const dispatch = useAppDispatch();
    const {i18n} = useTranslation();

    function changeLng(lng: "ro" | "ru") {
        i18n.changeLanguage(lng);
        dispatch(setUserLanguage(lng));
    }

    return (
        <Flex mt={20} justify={'center'}>
            <Text style={{cursor: "pointer"}} td={i18n.language === "ro" ? "underline" : "none"}
                  c={i18n.language === "ro" ? 'teal.5' : undefined}
                  onClick={() => changeLng("ro")}
            >Ro</Text>
            <Text> &nbsp;/&nbsp; </Text>
            <Text style={{cursor: "pointer"}} td={i18n.language === "ru" ? "underline" : "none"}
                  c={i18n.language === "ru" ? 'teal.5' : undefined}
                  onClick={() => changeLng("ru")}
            >Ru</Text>
        </Flex>
    )
}