import React from "react";
import { Select, TextInput } from "@mantine/core";

import { notification } from "../../../utils/notification";
import { useHallFormContext } from "./Hall";

//API
import { useGetBuildingsQuery } from "../../../api/building";
import { IHall, useCreateHallMutation, useUpdateHallMutation } from "../../../api/hall";
import { useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";


const HallForm = (props: { recordId?: string, onClose: () => void }) => {
    const { t } = useTranslation();
    const form = useHallFormContext();
    const selectedEntity = useAppSelector(state => state.auth.selectedEntity);
    const [createHall] = useCreateHallMutation();
    const [updateHall] = useUpdateHallMutation();

    const { data: buildingsData = { results: [] } } = useGetBuildingsQuery({
        entity: selectedEntity
    });


    async function onSubmit(values: IHall) {
        try {
            props.recordId ?
                await updateHall({ ...values, id: Number(props.recordId) }).unwrap() :
                await createHall(values).unwrap();

            notification({
                type: "success",
                title: props.recordId ? t("halls.notifications.updated") : t("halls.notifications.created")
            });
            props.onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <form id={"hallForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values);
        })}>
            <TextInput
                label={t("halls.form.name")}
                required
                {...form.getInputProps("name")}
            />
            <Select
                required
                mt={15}
                label={t("halls.form.building")}
                placeholder={t('common.select')}
                data={buildingsData.results.map((b) => {
                    return { value: String(b.id), label: b.name || String(b.id) };
                })}
                comboboxProps={{
                    zIndex: 500
                }}
                {...form.getInputProps("building")}
            />
        </form>
    );
};
export default HallForm;
