import React, { useEffect, useState } from "react";
import { ActionIcon, Divider, Group, Paper, Text, TextInput } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { MoreHorizontal } from "lucide-react";

//Components
import CrudModal from "../../../components/CrudModal/CrudModal";
import PageContainer from "../../../components/PageContainer";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import { notification } from "../../../utils/notification";

//API
import {
    IEntity,
    useCreateEntityMutation,
    useGetEntitiesQuery, useReadEntityQuery,
    useRemoveEntityMutation,
    useUpdateEntityMutation
} from "../../../api/entity";

import EntityInfo from "./EntityInfo";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";

const initialModalState = {
    opened: false,
    inEdit: false
};
export const [EntityFormProvider, useEntityFormContext, useEntityForm] =
    createFormContext<IEntity>();

const Entity = () => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<{
        opened: boolean,
        record?: number,
        inEdit: boolean
    }>(initialModalState);
    const [page, setPage] = useState(1);
    const page_size = 10;
    const form = useEntityForm({
        initialValues: {
            name: ""
        }
    });

    const [createEntity] = useCreateEntityMutation();
    const [updateEntity] = useUpdateEntityMutation();
    const [removeEntity] = useRemoveEntityMutation();

    const { data: entityRecord, isFetching: isFetchingRecord } = useReadEntityQuery({ id: modalState.record || 0 }, {
        skip: !modalState.record
    });

    const { data = { results: [], count: 0 }, isFetching } = useGetEntitiesQuery({
        page,
        page_size: 10
    });

    async function onDelete() {
        try {
            modalState.record && await removeEntity({ id: modalState.record }).unwrap();
            notification({ type: "success", title: t("entities.notifications.deleted") });
            setModalState(initialModalState);
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    async function onSubmit(values: IEntity) {
        try {
            modalState.record ?
                await updateEntity({ id: modalState.record, ...values }).unwrap() :
                await createEntity(values).unwrap();
            notification({
                type: "success",
                title: modalState.record ? t("entities.notifications.updated") : "Компания t('entities.notifications.created')"
            });
            setModalState(initialModalState);
            form.reset();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    useEffect(() => {
        if (modalState.record && modalState.inEdit) {
            form.setValues({
                ...entityRecord,
                address: entityRecord.address ? String(entityRecord.address.id) : "",
                start: new Date(entityRecord.start)
            });
        }
    }, [modalState.record, modalState.inEdit, entityRecord]);

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>{t("entities.entities")}</Text>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                columns={[
                    { label: t("entities.form.name"), name: "name" },
                    { label: t("addresses.address"), name: "country", render: (_, record) =>  {
                        return `${record.country} ${record.city || ""} ${record.street || ""} ${record?.street_number || ""}`
                } },
                    {
                        name: "actions", label: t("common.actions"), type: "actions", width: "50px", render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"} onClick={() => setModalState({
                                    opened: true,
                                    record: record.id,
                                    inEdit: false
                                })}>
                                    <MoreHorizontal />
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => setModalState({ opened: true, inEdit: true })}
                createTitle={t("entities.create")}
            />
            <CrudModal opened={modalState.opened}
                       title={modalState.record ? entityRecord?.name : t("entities.create")}
                       formId={"entityForm"}
                       type={"drawer"}
                       isUpdate={Boolean(modalState.record)}
                       onClose={() => {
                           setModalState(initialModalState);
                           form.reset();
                       }}
                       inEdit={modalState.inEdit}
                       setEdit={(isEdit) => {
                           setModalState(prevState => {
                               return {
                                   ...prevState,
                                   inEdit: isEdit
                               };
                           });
                       }}
                       onDelete={onDelete}>
                {modalState.inEdit ? (
                    <form id={"entityForm"} onSubmit={form.onSubmit(async (values) => {
                        await onSubmit(values);
                    })}>
                        <TextInput
                            label={t("entities.form.name")}
                            required
                            {...form.getInputProps("name")}
                        />
                        <Paper py={20}>
                            <Text>{t("addresses.address")}</Text>
                            <Divider my={10} />
                            <Group gap={"lg"} justify={"space-between"}>
                                <TextInput
                                    w={'47%'}
                                    required
                                    maxLength={2}
                                    label={t("addresses.form.country")}
                                    {...form.getInputProps("country")}
                                />
                                <TextInput
                                    w={'47%'}
                                    label={t("addresses.form.city")}
                                    {...form.getInputProps("city")}
                                />
                            </Group>
                            <TextInput
                                mt={15}
                                label={t("addresses.form.street")}
                                {...form.getInputProps("street")}
                            />

                            <Group mt={15} gap={"lg"} justify={"space-between"}>
                                <TextInput
                                    w={'30%'}
                                    label={t("addresses.form.street_number")}
                                    {...form.getInputProps("street_number")}
                                />
                                <TextInput
                                    w={'30%'}
                                    label={t("addresses.form.block")}
                                    {...form.getInputProps("block")}
                                />
                                <TextInput
                                    w={'30%'}
                                    label={t("addresses.form.index")}
                                    {...form.getInputProps("postcode")}
                                />
                            </Group>
                        </Paper>
                    </form>
                ) : (modalState.opened && !modalState.inEdit) && (
                    <EntityInfo record={entityRecord} isFetching={isFetchingRecord} />
                )}
            </CrudModal>
        </PageContainer>
    );
};
export default Entity;
