import { ActionIcon, AppShell, Burger, Group, useMantineColorScheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { useAppSelector } from "../app/hooks";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import { RemoveScroll } from "@mantine/core";
import { ChevronLeft, MenuIcon } from "lucide-react";
import { useLocation } from "react-router-dom";

const Layout = (props: { children: React.ReactNode }) => {
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    const { colorScheme } = useMantineColorScheme();
    const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
    const location = useLocation();
    const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
    return (
        <AppShell
            header={{ height: 60 }}
            layout="alt"
            disabled={!Boolean(accessToken) || location.pathname.startsWith("/buy-widget")}
            navbar={{
                width: 250,
                breakpoint: "sm",
                collapsed: { mobile: !mobileOpened, desktop: !desktopOpened }
            }}
            className={RemoveScroll.classNames.zeroRight}
            padding="md"
        >
            <AppShell.Header className={RemoveScroll.classNames.fullWidth}
                             zIndex={200}
                             bg={colorScheme === "light" ? "gray.2" : "dark.6"}>
                <Group h="100%" px="md">
                    <ActionIcon
                        onClick={toggleDesktop}
                        c={colorScheme === "dark" ? "gray.1" : "dark.8"}
                        variant={"transparent"}
                        visibleFrom={"sm"}
                    >
                        {desktopOpened ? <ChevronLeft /> : <MenuIcon />}
                    </ActionIcon>
                    <ActionIcon
                        onClick={toggleMobile}
                        c={colorScheme === "dark" ? "gray.1" : "dark.8"}
                        variant={"transparent"}
                        hiddenFrom="sm"
                    >
                        {mobileOpened ? <ChevronLeft /> : <MenuIcon />}
                    </ActionIcon>

                    <Header />
                </Group>
            </AppShell.Header>
            <AppShell.Navbar p="md" bg={"dark.6"} style={{ borderRight: 0 }}>
                <Sidebar />
            </AppShell.Navbar>
            <AppShell.Main bg={colorScheme === "dark" ? "dark.8" : "gray.1"}

            >{props.children}</AppShell.Main>
        </AppShell>
    );
};

export default Layout;
