import dayjs from "dayjs";
import { IUser } from "../../api/user";
import InfoItem from "./InfoItem";
import { useTranslation } from "react-i18next";

const AuditRecord = (record: { modified?: Date, modifier?: IUser, author?: IUser, created?: Date }) => {
    const { t } = useTranslation();
    return (
        <>
            <InfoItem value={dayjs(record.created).format("DD MMMM YYYY HH:MM")} label={t('audit.createDate')} />
            <InfoItem
                value={record.author?.first_name ? record.author?.first_name + " " + record.author?.last_name : "Не указан"}
                label={t('audit.author')} />
            <InfoItem value={dayjs(record.modified).format("DD MMMM YYYY HH:mm")} label={t('audit.lastChange')} />
            <InfoItem value={record.modifier?.first_name ? record.modifier?.first_name + " " + record.modifier?.last_name : "Не указан"}
                      label={t('audit.modifier')} />
        </>
    );
};
export default AuditRecord;
