function intersectDim(e: PointerEvent, containerRef: any, offset: {
    x: number,
    y: number
}, startX: number, startY: number): SVGRect {

    const rect = containerRef.current.createSVGRect();
    const parentOffset = containerRef.current.getBoundingClientRect();

    const fw = (offset.x - e.clientX);
    const fh = (offset.y - e.clientY);
    rect.width = fw < 0 ? fw * -1 : fw;
    rect.height = fh < 0 ? fh * -1 : fh;
    rect.y = startY;
    rect.x = startX;

    if ((e.clientX - parentOffset.left) < startX) {
        const w = (e.clientX - parentOffset.left) - startX;
        rect.x = e.clientX - parentOffset.left;
        rect.width = w < 0 ? w * -1 : w;
    }

    if ((e.clientY - parentOffset.top) < startY) {
        const h = (e.clientY - parentOffset.top) - startY;
        rect.y = e.clientY - parentOffset.top;
        rect.height = h < 0 ? h * -1 : h;
    }

    return rect;
}

function drawSelectionArea(e: PointerEvent, selectionAreaRef: any, containerRef: any, startX: number, startY: number) {
    const selectionArea = selectionAreaRef.current;

    if (selectionArea) {
        const point = getSVGPoint(e.clientX, e.clientY, containerRef.current);

        selectionArea.setAttribute("x", String(startX));
        selectionArea.setAttribute("y", String(startY));

        const x = parseInt(selectionArea.getAttribute("x") || "0");
        const y = parseInt(selectionArea.getAttribute("y") || "0");

        //width and height of selection area
        const w = point.x - x;
        const h = point.y - y;

        selectionArea.setAttribute("width", String(w < 0 ? w * -1 : w));
        selectionArea.setAttribute("height", String(h < 0 ? h * -1 : h));


        //negative width
        if (point.x < startX) {
            const w = point.x - startX;
            selectionArea.setAttribute("x", String(point.x));
            selectionArea.setAttribute("width", String(w < 0 ? w * -1 : w));
        }

        //negative height
        if (point.y < startY) {
            const h = point.y - startY;
            selectionArea.setAttribute("y", String(point.y));
            selectionArea.setAttribute("height", String(h < 0 ? h * -1 : h));
        }
    }
}

function getSVGPoint(clientX: number, clientY: number, svg: SVGSVGElement) {
    const pt = svg.createSVGPoint();
    pt.x = clientX;
    pt.y = clientY;
    return pt.matrixTransform(svg.getScreenCTM()!.inverse());
}

function isStartDrawing(e: PointerEvent, containerRef: any, startX: number, startY: number, offsetN: number = 6) {
    const point = getSVGPoint(e.clientX, e.clientY, containerRef.current);
    const xOffset = point.x - startX < 0 ? (point.x - startX) * -1 : point.x - startX;
    const yOffset = point.y - startY < 0 ? (point.y - startY) * -1 : point.y - startY;

    return xOffset > offsetN || yOffset > offsetN;

}

export {
    intersectDim,
    drawSelectionArea,
    getSVGPoint,
    isStartDrawing
};
