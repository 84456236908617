import PageContainer from "../../../components/PageContainer";
import {useGetEventsQuery} from "../../../api/event";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import {useState} from "react";
import dayjs from "dayjs";
import {Button} from "@mantine/core";
import {useNavigate} from "react-router-dom";

const SeatValidate = () => {
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const page_size = 10;
    const {data: events = {results: [], count: 0}, isFetching} = useGetEventsQuery({
        ordering: "start",
        page,
        page_size,
        start_gte: dayjs(new Date()).format("YYYY-MM-DDT00:00:00")
    })

    return (
        <PageContainer>
            <DefaultTable
                data={{
                    ...events,
                    totalPages: Math.ceil(events.count / page_size)
                }}
                columns={[
                    {label: "Наименование", name: "name"},
                    {
                        label: "Дата начала мероприятия", name: "start", render: (value) => {
                            return dayjs(value).format("DD-MM-YYYY HH:MM")
                        }
                    },
                    {
                        label: "", name: "_actions", render: (value, row) => {
                            const total = Date.parse(row.start) - Date.parse(new Date().toDateString());
                            // const seconds = Math.floor((total / 1000) % 60);
                            // const minutes = Math.floor((total / 1000 / 60) % 60);
                            // const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
                            const days = Math.floor(total / (1000 * 60 * 60 * 24));

                            return (
                                <Button size={'xs'}
                                        disabled={days > 3}
                                        onClick={() => {
                                            navigate(`/management/ticket-validation/${row.id}`)
                                        }}
                                >
                                    Начать посадку
                                </Button>
                            )
                        }
                    }
                ]}
                isFetching={isFetching}
                page={page}
                onChangePage={setPage}
            />
        </PageContainer>
    )
}
export default SeatValidate;