import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    ActionIcon,
    Button,
    ColorInput,
    ColorSwatch,
    Divider,
    Flex, LoadingOverlay,
    NumberInput,
    Paper,
    Text, TextInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Pencil } from "lucide-react";
import { notification } from "../../../../utils/notification";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setSelectedZone, setZones } from "../prices.slice";
import CrudModal from "../../../../components/CrudModal/CrudModal";

//API
import {
    IZone,
    useCreateZoneMutation,
    useGetZonesQuery, useReadZoneQuery,
    useRemoveZoneMutation,
    useUpdateZoneMutation
} from "../../../../api/zone";
import classes from "./Zones.module.css";
import { useTranslation } from "react-i18next";
import { useConfirmationModal } from "../../../../hooks/useConfirmationModal";

const initialModalState = {
    opened: false
};

const Zones = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useAppDispatch();
    const [modalState, setModalState] = useState<{ opened: boolean, record?: number }>(initialModalState);
    const [zoneCount, setZoneCount] = useState<any>({});
    const selectedZone = useAppSelector(state => state.schema.selectedZone);
    const data = useAppSelector(state => state.schema.data);
    const [ConfirmModal, confirmDelete] = useConfirmationModal({});
    useEffect(() => {
        const dataAr = Object.keys(data.data);
        let obj: { [key: number]: number } = {};
        dataAr.forEach(d => {
            const item = data.data[d];
            if (item.zone) {
                obj[item.zone] ? obj[item.zone] += 1 : obj[item.zone] = 1;
            }
        });
        setZoneCount(obj);
    }, [JSON.stringify(data.data)]);

    const { data: fetchedZones = [], isFetching } = useGetZonesQuery({ id: params.id || "" }, {
        skip: !params.id
    });
    const { data: zoneRecord } = useReadZoneQuery({ id: modalState.record || "" }, {
        skip: !modalState.record
    });
    const [createZone] = useCreateZoneMutation();
    const [updateZone] = useUpdateZoneMutation();
    const [removeZone] = useRemoveZoneMutation();

    useEffect(() => {
        if (fetchedZones) {
            dispatch(setZones(fetchedZones));
        }
    }, [JSON.stringify(fetchedZones), dispatch]);

    useEffect(() => {
        if (zoneRecord) {
            form.setValues(JSON.parse(JSON.stringify(zoneRecord)));
        }
    }, [zoneRecord]);

    const form = useForm<IZone>({
        initialValues: {
            price: 200,
            properties: {
                color: ""
            }
        }
    });

    function onOpenCreate() {
        const randomColor = "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
        form.setValues({
            properties: {
                color: randomColor
            },
            price: 200
        });
        setModalState({ opened: true });
    }

    async function onSubmit(values: IZone) {
        const zoneData = {
            ...values,
            event: params.id,
            name: String(values.price)
        };
        try {
            modalState.record ?
                await updateZone(zoneData).unwrap() :
                await createZone(zoneData).unwrap();
            notification({
                type: "success",
                title: modalState.record ? t(`priceEditor.notifications.priceCatUpdated`) : t(`priceEditor.notifications.priceCatCreated`)
            });
            setModalState(initialModalState);
            form.reset();
        } catch (e) {
            console.log(e);
            notification({ type: "error", title: t("common.errorDefaultMessage") });
        }

    }

    async function onDelete() {
        try {
            modalState.record && await removeZone(modalState.record).unwrap();
            notification({ type: "success", title: t(`priceEditor.notifications.priceCatRemoved`) });
            setModalState(initialModalState);
        } catch (e) {
            notification({ type: "error", title: t("common.errorDefaultMessage") });
            console.log(e);
        }
    }
    async function onClickConfirm() {
        const res = await confirmDelete();
        if (res) await onDelete();
    }


    return (
        <Paper pos={"relative"} mt={10} px={10}>
            <Button w={"100%"}
                    onClick={onOpenCreate}
            >{t(`priceEditor.createPriceZone`)}</Button>
            <Paper mt={10}>
                <Flex align={"center"} justify={"space-between"} p={3} style={{ cursor: "pointer" }}
                      className={classes.zoneContainer}
                      onClick={() => {
                          dispatch(setSelectedZone({ id: null }));
                      }}>
                    <Flex align={"center"}>
                        <ColorSwatch color={"gray"}
                                     size={15}
                                     withShadow={selectedZone.id === null}
                        />
                        <Text fz={"sm"} ml={10}>N/A</Text>
                    </Flex>
                </Flex>
                <Divider color={selectedZone.id === null ? "red.5" : "gray.2"} />
                {fetchedZones.map(zone => {
                    return (
                        <React.Fragment key={zone.id}>
                            <Flex align={"center"} justify={"space-between"} p={3} style={{ cursor: "pointer" }}
                                  className={classes.zoneContainer}
                                  onClick={() => {
                                      dispatch(setSelectedZone(zone));
                                  }}>
                                <Flex align={"center"}>
                                    <ColorSwatch color={zone.properties.color}
                                                 size={15}
                                                 withShadow={selectedZone.id === zone.id}
                                    />
                                    <Text fz={"sm"} ml={10}>{zone.price ? String(zone.price).split(".")[0] : ""}</Text>
                                    {zoneCount[zone.id || 0] ? (
                                        <><Text fz={11}
                                                pl={5}>x {zoneCount[zone.id || 0]} = {zoneCount[zone.id || 0] * (zone.price || 1)}</Text></>
                                    ) : <></>
                                    }

                                </Flex>
                                <ActionIcon variant={"transparent"}
                                            className={classes.zoneEditIcon}
                                            color={"gray.5"}
                                            size={"sm"}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setModalState({ opened: true, record: zone.id });
                                            }}
                                            ml={30}
                                >
                                    <Pencil size={15} />
                                </ActionIcon>
                            </Flex>
                            <Divider color={selectedZone.id === zone.id ? "red.5" : "gray.2"} />
                        </React.Fragment>
                    );
                })}
            </Paper>
            <CrudModal opened={modalState.opened}
                       withDelete={true}
                       hideActions={true}
                       title={modalState.record ? t(`priceEditor.updatePriceCategory`) : t(`priceEditor.createPriceCategory`)}
                       isUpdate={Boolean(modalState.record)}
                       formId={"zoneForm"}
                       onClose={() => {
                            setModalState(initialModalState);
                            form.reset();
                        }}
                       onDelete={onDelete}>
                <form id={"zoneForm"} onSubmit={form.onSubmit(async (values) => {
                    await onSubmit(values);
                })}>
                    <NumberInput
                        label={t(`priceEditor.price`)}
                        {...form.getInputProps("price")}
                    />
                    <ColorInput
                        label={t(`priceEditor.color`)}
                        popoverProps={{
                            zIndex: 500
                        }}
                        {...form.getInputProps("properties.color")}
                        onChange={(v) => {
                            form.setFieldValue("properties.color", v);
                        }}
                        value={form.getInputProps("properties.color").value}
                    />
                    <Divider mt={20} mb={10}/>
                    <Flex justify={'flex-end'} align={'center'}>
                        {Boolean(modalState.record) && (
                            <Button color={"red.5"} variant={"outline"} onClick={onClickConfirm}>
                                {t("common.remove")}
                            </Button>
                        )}
                        <Button ml={5} type={"submit"}>
                            {modalState.record ? t("common.update") : t("common.save")}
                        </Button>
                    </Flex>
                </form>
            </CrudModal>
            <ConfirmModal/>
            <LoadingOverlay visible={isFetching} />
        </Paper>
    );
};
export default Zones;
