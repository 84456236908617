import React from "react";
import { Paper } from "@mantine/core";

const PageContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Paper p={10} bg={'transparent'}>
      {children}
    </Paper>
  );
};

export default PageContainer;
