import { Modal, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { IInvoice, useLazyGetInvoicesQuery } from "../../../api/invoices";
import dayjs from "dayjs";

type TProps = {
    isOpen: boolean,
    onClose: () => any,
    booking: number | null
}

const InvoiceHistory = (props: TProps) => {
    const { isOpen, onClose, booking } = props;
    const [getInvoices] = useLazyGetInvoicesQuery();
    const [data, setData] = useState<IInvoice[]>([])
    useEffect(() => {
        if (booking && isOpen) {
            //get data
            fetchInvoices(booking);

        }
    }, [isOpen, booking])

    async function fetchInvoices(booking: number) {
        const invoices = await getInvoices({ booking }).unwrap();
        setData(invoices.results);
        console.log(invoices)
    }

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            centered={true}
            size={'lg'}
        
            title={`История заказа ${booking}`}
        >
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Td>Транзакция</Table.Td>
                        <Table.Td>Дата</Table.Td>
                        <Table.Td>Автор</Table.Td>
                        <Table.Td>Статус</Table.Td>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {data.map(invoice => {
                        return (
                            <Table.Tr key={invoice.id}>
                                <Table.Td>{invoice.id}</Table.Td>
                                <Table.Td>{dayjs(invoice.timestamp).format("DD-MM-YYYY HH:mm")}</Table.Td>
                                <Table.Td>{invoice.customer?.first_name} {invoice.customer?.last_name} {invoice.customer.email}</Table.Td>
                                <Table.Td>{invoice.status}</Table.Td>
                            </Table.Tr>
                        )
                    })}
                </Table.Tbody>
            </Table>
        </Modal>
    )
}
export default InvoiceHistory;