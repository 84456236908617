import { useGetEntitiesQuery } from "../api/entity";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Loader, Select, Skeleton } from "@mantine/core";
import { setEntity } from "../pages/authorization/authorization.slice";
import classes from './Sidebar/Sidebar.module.css'
const EntityChanger = () => {
  const { data = { results: [] }, isFetching } = useGetEntitiesQuery({});
  const dispatch = useAppDispatch();
  const selectedEntity = useAppSelector((state) => state.auth.selectedEntity);
  return (
      data.results.length ? (
          <Select
              size={'md'}
              classNames={{
                  input: classes.entityChangerRoot
              }}
              allowDeselect={false}
              leftSection={isFetching && <Loader size={"xs"} />}
              value={String(selectedEntity)}
              onChange={(v) => dispatch(setEntity(Number(v)))}
              placeholder="Выбрать"
              data={data.results.map((i) => {
                  return { value: String(i.id), label: i.name || String(i.id) };
              })}
          />
      ) : (
          <Skeleton h={42} w={237}/>
      )
  );
};

export default EntityChanger
