import React, {useState} from "react"
import {Button, Modal} from "@mantine/core"
import {useTranslation} from "react-i18next";

export const useConfirmationModal = ({title,}: { title?: string }): [any, (title?:string) => Promise<boolean>] => {
    const {t} = useTranslation();
    const [title1, setTitle1] = useState("")
    const [promise, setPromise] = useState<{
        resolve: (value: boolean) => void
    } | null>(null)

    const confirm = (title?:string) =>{
      title && setTitle1(title)
      return new Promise<boolean>((resolve) => {
        setPromise({resolve})
      })
    }


    const handleClose = () => {
        setPromise(null)
    }

    const handleConfirm = () => {
        promise?.resolve(true)
        handleClose()
    }

    const handleCancel = () => {
        promise?.resolve(false)
        handleClose()
    }
    const ConfirmModal = () => (
        <Modal
            opened={promise !== null}
            onClose={handleClose}
            centered
            zIndex={450}
            title={title || title1 || t('common.confirmation')}
        >
            <Button onClick={handleConfirm}>{t("common.yes")}</Button>
            <Button onClick={handleCancel} variant={"outline"} ml={5}>
                {t("common.no")}
            </Button>
        </Modal>
    )

    return [ConfirmModal, confirm]
}
