import React from "react"

import { Center, Group, Text, UnstyledButton, Table } from "@mantine/core"
import { ArrowUpNarrowWide, ChevronDown, ChevronUp } from "lucide-react"
import classes from "./DefaultTable.module.css"

interface ThProps {
  children: React.ReactNode
  reversed: boolean
  sorted: boolean

  onSort(): void

  width: number | string
  sortable: boolean
}

function Th({ children, reversed, sorted, onSort, width, sortable }: ThProps) {
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : ArrowUpNarrowWide
  return (
    <Table.Th style={{ width, fontWeight: 500 }}>
      {sortable ? (
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group>
            <Text fw={500} fz="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size="0.9rem" />
            </Center>
          </Group>
        </UnstyledButton>
      ) : (
        children
      )}
    </Table.Th>
  )
}

export default Th
