import { useEffect, useState } from "react";
import { ActionIcon, Text } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { MoreHorizontal } from "lucide-react";

//Components
import { notification } from "../../../utils/notification";
import PageContainer from "../../../components/PageContainer";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import CrudModal from "../../../components/CrudModal/CrudModal";
import HallForm from "./HallForm";

//API
import { IHall, useGetHallsQuery, useReadHallQuery, useRemoveHallMutation } from "../../../api/hall";
import HallInfo from "./HallInfo";
import { useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";

const initialModalState = {
    opened: false,
    inEdit: false
};
export const [HallFormProvider, useHallFormContext, useHallForm] =
    createFormContext<IHall>();
const Hall = () => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<{ opened: boolean, record?: "", inEdit: boolean }>(initialModalState);
    const [page, setPage] = useState(1);
    const selectedEntity = useAppSelector(state => state.auth.selectedEntity);
    const [removeHall] = useRemoveHallMutation();
    const page_size = 10;
    const { data = { results: [], count: 0 }, isFetching } = useGetHallsQuery({
        page,
        page_size: 10,
        entity: selectedEntity
    });

    const { data: hallRecord, isFetching: isFetchingRecord } = useReadHallQuery({
            id: Number(modalState.record)
        },
        { skip: !modalState.record });

    const form = useHallForm({
        initialValues: {
            name: ""
        }
    });
    useEffect(() => {
        if (modalState.record && modalState.inEdit) {
            form.setValues({
                ...hallRecord,
                building: hallRecord.building ? String(hallRecord.building.id) : ""
            });
        }
    }, [modalState.record, modalState.inEdit, hallRecord]);

    async function onDelete() {
        try {
            modalState.record && await removeHall({ id: modalState.record }).unwrap();
            notification({ type: "success", title: t("halls.notifications.deleted") });
            setModalState(initialModalState);
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>{t("halls.halls")}</Text>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                columns={[
                    { label: t("halls.form.name"), name: "name" },
                    { label: t("halls.form.building"), name: "building", render: (record) => record.name },
                    {
                        name: "actions", label: t("common.actions"), type: "actions", width: "50px", render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"} onClick={() => setModalState({
                                    opened: true,
                                    record: record.id,
                                    inEdit: false
                                })}>
                                    <MoreHorizontal />
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => setModalState({ opened: true, inEdit: true })}
                createTitle={t("halls.create")}
            />
            <CrudModal
                type={"drawer"}
                title={modalState.record ? hallRecord?.name : t("halls.create")}
                opened={modalState.opened}
                formId={"hallForm"}
                isUpdate={Boolean(modalState.record)}
                onClose={() => {
                    setModalState(initialModalState);
                    form.reset();
                }}
                onDelete={onDelete}
                inEdit={modalState.inEdit}
                setEdit={(isEdit) => {
                    setModalState(prevState => {
                        return {
                            ...prevState,
                            inEdit: isEdit
                        };
                    });
                }}
            >
                <HallFormProvider form={form}>
                    {modalState.inEdit ? (
                        <HallForm
                            onClose={() => {
                                setModalState(prevState => {
                                    return {
                                        ...prevState,
                                        inEdit: !Boolean(prevState.record),
                                        opened: Boolean(prevState.record)
                                    };
                                });
                            }}
                            recordId={modalState.record}
                        />
                    ) : (modalState.opened && !modalState.inEdit) && (
                        <HallInfo record={hallRecord} isFetching={isFetchingRecord} />
                    )}
                </HallFormProvider>
            </CrudModal>
        </PageContainer>
    );
};

export default Hall;
