import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IZone} from "../../api/zone";
import {IEvent} from "../../api/event";
import {showNotification} from "@mantine/notifications";
import {activateBoxOffice, cancelReservation, checkStatus, onSeatClickThunk} from "./sales.thunks";
import {IUser} from "../../api/user";
import {showErrorMessages} from "../../app/api";
import {IBookingModify} from "../../api/booking";
import {uniqBy} from "lodash";

export type ISeat = {
    seatId: number,
    key: string,
    zone: number,
    ticket?: number,
    status?: "free" | "reserved" | "sold" | "disabled"
}

export type SeatsDataSchema = {
    data: {
        [key: string]: {
            id: number,
            zone: number | null,
            ticket?: number,
            selected: boolean,
            status: "free" | "reserved" | "sold" | "disabled"
            rect: {
                x: string,
                y: string
            }
        }
    }
}

export interface ISalesState {
    loading: boolean;
    showHints: boolean;
    zones: IZone[];
    event: IEvent;
    cashierToken: string;
    isActiveToken: boolean;
    closedBoxOfficeBy?: IUser;
    activeBoxOffice?: string;
    showOnly?: "free" | "reserved" | "sold";
    selectedSeats: ISeat[];
    totalSeats: number;
    data: SeatsDataSchema;
    initialData: SeatsDataSchema;
    selectedBooking?: IBookingModify;
    image?: string;
}

const initialState: ISalesState = {
    loading: false,
    showHints: true,
    data: {data: {}},
    initialData: {data: {}},
    selectedSeats: [],
    isActiveToken: false,
    activeBoxOffice: localStorage.getItem("activeBoxOffice") || "",
    cashierToken: localStorage.getItem("cashierToken") || "",
    zones: [],
    event: {},
    totalSeats: 0
};

export const pricesSlice = createSlice({
    name: "scheme",
    initialState,
    reducers: {
        setEvent: (state, action: PayloadAction<IEvent>) => {
            state.event = action.payload;
        },
        setZones: (state, action) => {
            state.zones = action.payload;
        },
        setSelectedSeats: (state) => {
            const activeBooking = JSON.parse(localStorage.getItem("activeBooking") || "{}");
            const bookDurationMilliseconds = (state.event.max_booking_duration || 1) * 60 * 60 * 1000;
            console.log(bookDurationMilliseconds, new Date().getTime() - new Date(activeBooking.created).getTime())
            if (new Date().getTime() - new Date(activeBooking.created).getTime() >= bookDurationMilliseconds) {
                state.selectedBooking = undefined;
                localStorage.removeItem('activeBooking')
                return;
            }
            if (activeBooking.tickets) {
                state.selectedBooking = activeBooking;
                for (let ticket of activeBooking.tickets) {
                    if (ticket.status === 'reserved') {
                        state.data.data[ticket.key] = {
                            ...state.data.data[ticket.key],
                            selected: ticket.status === 'reserved'
                        }
                    }
                }
            }
        },
        clearSelectedSeats: state => {
            state.selectedSeats = [];
        },
        clearSelectedBooking: state => {
            const activeTickets = state.selectedBooking?.tickets.filter(f => f.status === 'reserved');
            if (activeTickets?.length && state.selectedBooking) {
                for (let ticket of state.selectedBooking.tickets) {
                    if (ticket.status === 'reserved') {
                        state.data.data[ticket.key] = {
                            ...state.data.data[ticket.key],
                            selected: false
                        }
                    }
                }
            }
            state.selectedBooking = undefined;
            localStorage.removeItem("activeBooking")
        },
        updateSelectedBooking: (state, action) => {
            const activeBooking = action.payload;
            localStorage.setItem("activeBooking", JSON.stringify(action.payload))
            if (activeBooking.tickets) {
                state.selectedBooking = activeBooking;
                for (let ticket of activeBooking.tickets) {
                    state.data.data[ticket.key] = {
                        ...state.data.data[ticket.key],
                        selected: ticket.status === 'reserved'
                    }
                }
            }
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setTotalSeats: (state, action) => {
            state.totalSeats = action.payload;
        },
        setCashierToken: (state, action: PayloadAction<string>) => {
            state.cashierToken = action.payload;
            localStorage.setItem("cashierToken", action.payload);
        },
        setIsActiveToken: (state, action: PayloadAction<boolean>) => {
            state.isActiveToken = action.payload;
        },
        changeActiveBoxOffice: (state, action: PayloadAction<string>) => {
            state.activeBoxOffice = action.payload;
            state.isActiveToken = false;
            localStorage.setItem("activeBoxOffice", action.payload);
        },
        setData: (state, action) => {
            state.data = action.payload;
            state.initialData = action.payload;
        },
        partialUpdateSeats: (state, action: PayloadAction<{ data: { event: number, seats: ISeat[] } }>) => {

            for (let seat of action.payload.data.seats) {
                state.data.data[seat.key] = {
                    ...state.data.data[seat.key],
                    ...seat,
                    selected: state.selectedBooking ? Boolean(state.selectedBooking?.tickets.find(f => f.key === seat.key)) : false
                }
            }
        },
        onClickSeat: (state, action: PayloadAction<{
            seatId: number,
            key: string,
            zone?: number | null,
            status?: "free" | "reserved" | "sold" | "disabled",
            ticket?: number
        }>) => {
            if (!action.payload.zone) return;
            //restrict select seats with different statuses
            if (state.selectedSeats.length && (state.selectedSeats[state.selectedSeats.length - 1].status !== action.payload.status)) return;

            //collect selected seats
            const seat = state.selectedSeats.find(f => f.seatId === action.payload.seatId);
            seat ? state.selectedSeats = state.selectedSeats.filter(f => f.seatId !== action.payload.seatId) :
                state.selectedSeats.push({
                    seatId: action.payload.seatId,
                    key: action.payload.key,
                    zone: action.payload.zone,
                    status: action.payload.status,
                    ticket: action.payload.ticket
                });

            //refresh selected seat in data
            state.data = {
                ...state.data,
                data: {
                    ...state.data.data,
                    [action.payload.key]: {
                        ...state.data.data[action.payload.key],
                        selected: !state.data.data[action.payload.key].selected
                    }
                }
            };
        },
        toggleHints: state => {
            state.showHints = !state.showHints;
        },
        setShowOnly: (state, action: PayloadAction<"free" | "reserved" | "sold" | undefined>) => {
            state.showOnly = action.payload;
        },
        setImage: (state, action: PayloadAction<string>) => {
            state.image = action.payload;
        },
        reset: (state) => {
            return {
                ...initialState,
                isActiveToken: state.isActiveToken,
                activeBoxOffice: localStorage.getItem("activeBoxOffice") || "",
                cashierToken: localStorage.getItem("cashierToken") || "",
                selectedBooking: undefined
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(activateBoxOffice.fulfilled, (state, {payload}) => {
                state.cashierToken = payload.token;
                state.isActiveToken = true;
                state.activeBoxOffice = String(payload.boxOfficeId);
                localStorage.setItem("activeBoxOffice", payload.boxOfficeId);
                localStorage.setItem("cashierToken", payload.token);
            })
            .addCase(activateBoxOffice.rejected, (state, {payload}) => {
                showErrorMessages(payload);
            });
        builder
            .addCase(checkStatus.fulfilled, (state, {payload}) => {
                if (!payload) return;
                if (payload.token_session) {
                    state.isActiveToken = true;
                    return;
                }
                state.closedBoxOfficeBy = payload.last_session.closed_by;
            })
            .addCase(checkStatus.rejected, () => {
                showNotification({
                    color: "red",
                    title: "Ошибка проверки статуса",
                    message: ""
                });
            });
        builder
            .addCase(onSeatClickThunk.fulfilled, (state, {payload}) => {
                if (!payload) return;

                const prevData = state.selectedBooking?.tickets.map(v => {
                    return {...v, status: 'disabled'}
                }) || []

                const newSelected = [...payload.tickets, ...prevData]
                const uniqTickets = uniqBy(newSelected, (v) => v.key)

                for (let seat of uniqTickets) {
                    state.data.data[seat.key] = {
                        ...state.data.data[seat.key],
                        selected: seat.status === 'reserved'
                    }
                }

                //if all tickets was unselected, remove active booking
                if (payload.status !== "active") {
                    localStorage.removeItem("activeBooking")
                    state.selectedBooking = undefined
                } else {
                    state.selectedBooking = payload
                    localStorage.setItem("activeBooking", JSON.stringify(payload))
                }


            })
        builder
            .addCase(cancelReservation.fulfilled, (state) => {
                state.selectedBooking = undefined;
            })
    }
});
export const {
    setZones,
    setLoading,
    setSelectedSeats,
    clearSelectedSeats,
    setCashierToken,
    setEvent,
    changeActiveBoxOffice,
    setData,
    onClickSeat,
    setIsActiveToken,
    toggleHints,
    setShowOnly,
    partialUpdateSeats,
    clearSelectedBooking,
    updateSelectedBooking,
    setImage,
    reset
} = pricesSlice.actions;
export default pricesSlice.reducer;
