import {
    BarChart3,
    CalendarDays,
    Users2, BuildingIcon, Slack,
    ActivitySquare, Sliders,
    Ticket, CandlestickChart, FileBarChart, Settings, Circle, Globe2
} from "lucide-react";
import {Divider, Image, NavLink, ScrollArea, useMantineTheme, Box, Center} from "@mantine/core";
import classes from "./Sidebar.module.css";
import {Link, useLocation} from "react-router-dom";
import EntityChanger from "../EntityChanger";
import {useAppSelector} from "../../app/hooks";
import {useTranslation} from "react-i18next";
import logo from "./logo2.svg";
import {roleCheck} from "../../utils/roleCheck";

interface Menu {
    title: string;
    icon?: any;
    url?: string;
    sub?: Menu[];
}


const Sidebar = () => {
    const roles = useAppSelector(state => state.auth.user.role);
    const showEntityChanger = roleCheck(roles, ["administrators", "price_managers"]);
    return (
        <>
            <Box mt={20}>
                <Center>
                    <Image src={logo} w={130}/>
                </Center>
            </Box>
            <div className={classes.actionsArea}>
                {showEntityChanger && <EntityChanger/>}
            </div>
            {!showEntityChanger && <Divider/>}
            <ScrollArea className={classes.links}>
                <div className={classes.linksInner}>
                    <Links/>
                </div>
            </ScrollArea>
        </>
    );
};

export default Sidebar;

const Links = () => {
    const roles = useAppSelector(state => state.auth.user.role);
    let location = useLocation();
    const theme = useMantineTheme();
    const {t} = useTranslation();
    const reports = [
        {
            icon: <Circle size={10}/>,
            title: t("menu.reports_sold"),
            url: "/reports/ticket-sales",
            allowed: ["administrators", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        },
        {
            icon: <Circle size={10}/>,
            title: t("menu.reports_byPrices"),
            url: "/reports/prices",
            allowed: ["administrators", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        },
        {
            icon: <Circle size={10}/>,
            title: t("menu.reports_byPeriod"),
            url: "/reports/by-period",
            allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        },
        {
            icon: <Circle size={10}/>,
            title: t("menu.reports_byCashBox"),
            url: "/reports/cashiers-sales",
            allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        },
        {
            icon: <Circle size={10}/>,
            title: t("menu.reports_tariff"),
            url: "/reports/price-list",
            allowed: ["administrators", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        },
        {
            icon: <Circle size={10}/>,
            title: t("menu.reports_ticketHistory"),
            url: "/report/",
            allowed: ["administrators", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        }
    ]
    const menuItems = [
        {
            title: t("menu.statistics"),
            icon: <BarChart3 className="text-green-500" style={{
                color: theme.colors.green[5]
            }}/>,
            url: "/",
            allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        },
        {
            title: t("menu.events"),
            icon: <CalendarDays style={{
                color: theme.colors.yellow[5]
            }}/>,
            url: "/events",
            allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers", "content_managers"]
        },
        {
            title: t("menu.management"),
            icon: <CandlestickChart style={{
                color: theme.colors.grape[5]
            }}/>,
            allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers", "content_managers"],
            sub: [
                {
                    title: t("menu.orders_cashier"),
                    icon: <Circle size={10}/>,
                    url: "/management/orders-cashier",
                    allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers", "content_managers"]
                },
                {
                    title: t("menu.orders_internet"),
                    icon: <Circle size={10}/>,
                    url: "/management/orders-internet",
                    allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers", "content_managers"]
                },
                {
                    title: t("menu.seat_validator"),
                    icon: <Circle size={10}/>,
                    url: "/management/seat-validate",
                    allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers", "content_managers"]
                },
            ]
        },
        {
            title: t("menu.users"),
            icon: <Users2 className="text-orange-500" style={{
                color: theme.colors.orange[5]
            }}/>,
            url: "/users",
            allowed: ["administrators"]
        },
        {
            title: t("menu.reports"),
            icon: <FileBarChart className="text-green-500" style={{
                color: theme.colors.red[5]
            }}/>,
            url: "/reports",
            sub: reports,
            allowed: ["administrators", "cashiers", "box_office_managers", "invoice_sale", "analytics", "price_managers"]
        },
        {
            title: t("menu.settings"),
            icon: <Settings style={{
                color: theme.colors.blue[5]
            }}/>,
            allowed: ["administrators"],
            sub: [
                {
                    title: t("menu.companies"),
                    icon: <Slack style={{
                        color: theme.colors.gray[5]
                    }}/>,
                    url: "/dictionary/entity"
                },
                {
                    title: t("menu.buildings"),
                    icon: <BuildingIcon style={{
                        color: theme.colors.gray[5]
                    }}/>,
                    url: "/dictionary/buildings"
                },
                {
                    title: t("menu.halls"),
                    icon: <ActivitySquare style={{
                        color: theme.colors.gray[5]
                    }}/>,
                    url: "/dictionary/halls"
                },
                {
                    title: t("menu.boxOffices"),
                    icon: <Ticket style={{
                        color: theme.colors.gray[5]
                    }}/>,
                    url: "/dictionary/box-offices"
                },
                {
                    title: t("menu.schemes"),
                    icon: <Sliders style={{
                        color: theme.colors.gray[5]
                    }}/>,
                    url: "/dictionary/schemes"
                }
            ]
        },
        {
            title: t("menu.web"),
            icon: <Globe2 style={{
                color: theme.colors.pink[5]
            }}/>,
            allowed: ["administrators"],
            sub: [{
                title: t("menu.guests"),
                icon: <Users2 style={{
                    color: theme.colors.gray[5]
                }}/>,
                url: "/guests",
                allowed: ["administrators"]
            },]
        }
    ];
    const currentMenu = filterByRole(menuItems, roles)

    function links(data: Menu[], isSub: boolean) {
        return data.map((item) => {
            if (item.sub) {
                return (
                    <NavLink key={item.title} label={item.title} leftSection={item.icon}
                             className={classes.navLink}
                             classNames={{
                                 chevron: classes.chevron,
                                 root: classes.navLink,
                                 label: classes.navLink_label
                             }}
                    >
                        {links(item.sub, true)}
                    </NavLink>
                );
            }
            return (
                <Link to={item.url || ""} className={classes.link} key={item.title}>
                    <NavLink
                        component={"span"}
                        label={item.title}
                        leftSection={item.icon}
                        active={item.url === location.pathname}
                        classNames={{
                            chevron: classes.chevron,
                            root: isSub ? classes.navLinkRootSub : classes.navLinkRoot
                        }}
                        color={'gray.5'}
                    />
                </Link>
            );
        });
    }

    return <>{links(currentMenu, false)}</>;
};

function filterByRole(array: any, roles: any) {
    return array.filter((obj: any) => {

        if (!obj.allowed) {
            return true
        }
        if (obj.sub) {
            if (roleCheck(roles, obj.allowed)) {
                obj.sub = filterByRole(obj.sub, roles);
                return obj.sub.length > 0;
            }
            return false
        }

        return roleCheck(roles, obj.allowed);
    });
}