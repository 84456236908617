import PageContainer from "../../components/PageContainer";
import { Avatar, Button, Card, Flex, Grid, PasswordInput, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IUserProfile, updateProfile } from "../authorization/authorization.slice";
import { useEffect } from "react";
import { useChangePasswordMutation, useUpdateProfileMutation } from "../../api/user";
import { notification } from "../../utils/notification";

const Profile = () => {
    const profile = useAppSelector(state => state.auth.user);
    const dispatch = useAppDispatch();
    const [changePassword] = useChangePasswordMutation();
    const [updateApiProfile] = useUpdateProfileMutation();
    const profileForm = useForm<IUserProfile>({
        initialValues: {
            first_name: "",
            last_name: "",
            phone: ""
        }
    });
    const changePwdForm = useForm({
        initialValues: {
            password: "",
            passwordRepeat: ""
        },
        validate: {
            password: (value) => {
                if (value.length < 8) {
                    return "Длина пароля не менее 8 символов";
                }
            },
            passwordRepeat: (value, values) => {
                if (value !== values.password) {
                    return "Пароли не совпадают";
                }
                return null;
            }

        }
    });
    const isTouchedProfileForm = profileForm.isDirty();
    const isTouchedChangePwdForm = changePwdForm.isDirty();

    useEffect(() => {
        if (profile) {
            profileForm.setValues(profile);
            profileForm.resetDirty(profile);
        }
    }, [JSON.stringify(profile)]);

    async function onSubmitChangePwdForm(values: { password: string, passwordRepeat: string }) {
        try {
            await changePassword({ password: values.password, passwordRepeat: values.passwordRepeat }).unwrap();
            notification({ type: "success", title: "Пароль успешно изменен" });
        } catch (e) {
            console.log(e);
            notification({ type: "error", title: "Произошла ошибка, обратитесь к администратору" });
        }
    }

    async function onSubmitProfileForm(values: IUserProfile) {
        try {
            const updatedProfile = await updateApiProfile(values).unwrap();
            notification({ type: "success", title: "Профиль успешно обновлен" });
            dispatch(updateProfile(updatedProfile));
        } catch (e) {
            console.log(e);
            notification({ type: "error", title: "Произошла ошибка, обратитесь к администратору" });
        }
    }

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>Профиль</Text>
            <Grid style={{ overflow: "visible" }} gutter={"xl"}>
                <Grid.Col span={{ base: 12, md: 4, lg: 3, xl: 3 }}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder h={250}>
                        <Flex justify={"center"} align={"center"} direction={"column"} h={"100%"}>
                            <Avatar size={"xl"}>
                                {profile.first_name?.charAt(0)}{profile.last_name?.charAt(0)}
                            </Avatar>
                            <Text pt={15} size={"xl"} c={"teal"}>{profile.first_name} {profile.last_name}</Text>
                            <Text pt={5} size={"xl"} c={"gray.7"}>{profile.phone}</Text>
                        </Flex>
                    </Card>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 8, lg: 9, xl: 9 }}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder mih={250}>
                        <form autoComplete={"off"} onSubmit={profileForm.onSubmit(async (values) => {
                            await onSubmitProfileForm(values);
                        })}>
                            <Grid mt={10}>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <TextInput placeholder={"Имя"} size={"lg"}
                                               required
                                               {...profileForm.getInputProps("first_name")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <TextInput placeholder={"Фамилия"} size={"lg"}
                                               required
                                               {...profileForm.getInputProps("last_name")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <TextInput placeholder={"Номер телефона"} size={"lg"}
                                               required
                                               {...profileForm.getInputProps("phone")}
                                    />
                                </Grid.Col>
                            </Grid>
                            <Flex mt={20}>
                                <Button type={"submit"} disabled={!isTouchedProfileForm} size={"xl"}>Обновить</Button>
                            </Flex>
                        </form>
                    </Card>
                </Grid.Col>
            </Grid>
            <Grid style={{ overflow: "visible" }} mt={20}>
                <Grid.Col span={12}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder h={250}>
                        <form autoComplete={"off"} onSubmit={changePwdForm.onSubmit(async (values) => {
                            await onSubmitChangePwdForm(values);
                        })}>
                            <Grid mt={10}>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <PasswordInput placeholder={"Новый Пароль"} size={"lg"}
                                                   {...changePwdForm.getInputProps("password")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <PasswordInput placeholder={"Повторите пароль"} size={"lg"}
                                                   {...changePwdForm.getInputProps("passwordRepeat")}
                                    />
                                </Grid.Col>
                            </Grid>
                            <Flex mt={20}>
                                <Button type={"submit"} disabled={!isTouchedChangePwdForm} size={"xl"}>Сменить
                                    пароль</Button>
                            </Flex>
                        </form>

                    </Card>
                </Grid.Col>
            </Grid>
        </PageContainer>
    );
};
export default Profile;
