import React, { useState } from "react";
import { Paper, TextInput, Select, MultiSelect, Checkbox, PasswordInput } from "@mantine/core";
import { notification } from "../../utils/notification";
import { useUsersFormContext } from "./Users";

//API
import {
    IUser,
    useCreateUserMutation,
    useSetPasswordMutation,
    useUpdateUserMutation
} from "../../api/user";
import { useGetUserGroupsQuery } from "../../api/userGroup";
import { useTranslation } from "react-i18next";

const UsersForm = (props: { recordId?: string, onClose: () => void }) => {
    const { t } = useTranslation();
    const form = useUsersFormContext();
    const [userPassword, setUserPassword] = useState<{ password: string, passwordRepeat: string }>({
        password: "",
        passwordRepeat: ""
    });
    const [passwordErrors, setPasswordErrors] = useState(({
        password: "",
        passwordRepeat: ""
    }));
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [setPassword] = useSetPasswordMutation();

    const { data: userGroups = [] } = useGetUserGroupsQuery({});

    async function onSubmit(values: IUser) {
        try {

            const passwordValidation = validatePasswords(userPassword.password, userPassword.passwordRepeat, props.recordId);

            if (passwordValidation.errors) {
                setPasswordErrors(passwordValidation.errors);
                return;
            }

            const res = props.recordId ?
                await updateUser(values).unwrap() :
                await createUser(values).unwrap();

            if (!props.recordId && (res && res.id)) {
                await setPassword({ id: res.id, ...userPassword }).unwrap();
            }


            notification({
                type: "success",
                title: props.recordId ? t('users.notifications.updated') : t('users.notifications.created')
            });
            props.onClose();
        } catch (e) {
            notification({
                type: "error",
                title: t('common.errorDefaultMessage')
            });
            console.log(e);
        }
    }

    function validatePasswords(password: string, passwordRepeat: string, recordId?: string) {

        //do not check password when update
        if (recordId) {
            return { "success": {} };
        }

        //passwords equality check
        if (password !== passwordRepeat) {
            return {
                "errors": {
                    password: t("users.errors.passwordMatch"),
                    passwordRepeat: t("users.errors.passwordMatch"),
                }
            };
        }

        if (password.length < 8) {
            return {
                "errors": {
                    password: t("users.errors.moreThan8"),
                    passwordRepeat: t("users.errors.moreThan8"),
                }
            };
        }

        return { "success": {} };
    }
    function onChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
        setUserPassword(prevState => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            };
        });
    }

    return (
        <form id={"usersForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values);
        })}>
            <Paper>
                <TextInput
                    label={t("users.form.firstName")}
                    {...form.getInputProps("first_name")}
                />
                <TextInput
                    mt={15}
                    label={t("users.form.lastName")}
                    {...form.getInputProps("last_name")}
                />
                <TextInput
                    mt={15}
                    label={"Email"}
                    required
                    {...form.getInputProps("email")}
                />
                <TextInput
                    mt={15}
                    label={t("users.form.phone")}
                    {...form.getInputProps("phone")}
                />
                <MultiSelect
                    required
                    mt={15}
                    label={t("users.form.groups")}
                    placeholder={t('common.select')}
                    data={userGroups.map((b) => {
                        return { value: b.name, label: b.label };
                    })}
                    comboboxProps={{
                        zIndex: 500
                    }}
                    {...form.getInputProps("set_groups")}
                />
                <Select
                    required
                    mt={15}
                    label={t("users.form.sex")}
                    placeholder={t('common.select')}
                    data={[
                        { label: t("users.form.sexItems.male"), value: "male" },
                        { label: t("users.form.sexItems.female"), value: "female" }
                    ]}
                    comboboxProps={{
                        zIndex: 500
                    }}
                    {...form.getInputProps("sex")}
                />
                {!props.recordId && (
                    <>
                        <PasswordInput
                            mt={15}
                            required
                            label={t("users.form.password")}
                            error={passwordErrors.password}
                            name={"password"}
                            onChange={onChangePassword}
                        />
                        <PasswordInput
                            mt={15}
                            required
                            name={"passwordRepeat"}
                            label={t("users.form.passwordRepeat")}
                            error={passwordErrors.passwordRepeat}
                            onChange={onChangePassword}
                        />
                    </>
                )}
                <Checkbox
                    mt={25}
                    label={t("users.form.isActive")}
                    labelPosition={"left"}
                    {...form.getInputProps("is_active", { type: "checkbox" })}
                />
            </Paper>
        </form>
    );
};
export default UsersForm;
