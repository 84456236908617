import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type TState = {
    selectedEvent?: number,
}

const initialState: TState = {}
export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setSelectedEvent: (state, action: PayloadAction<number | undefined>) => {
            state.selectedEvent = action.payload;
        }
    }
})

export const {
    setSelectedEvent
} = homeSlice.actions

export default homeSlice.reducer;