import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Group, Menu, rem, UnstyledButton } from "@mantine/core";
import cx from "clsx";
import { LogOut, Settings, User2Icon } from "lucide-react";

import classes from "./Header.module.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {clearAuthorization, signOutAsync} from "../../pages/authorization/authorization.slice";
import { useTranslation } from "react-i18next";

const UserMenu = () => {
    const { t } = useTranslation();
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const user = useAppSelector(state => state.auth.user)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <Menu
            width={260}
            position="bottom-end"
            transitionProps={{ transition: "pop-top-right" }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
            zIndex={1000}
        >
            <Menu.Target>
                <UnstyledButton
                    className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                    <Group gap={7}>
                        <Avatar size={35}>
                            {user.first_name?.charAt(0)}{user.last_name?.charAt(0)}
                        </Avatar>
                    </Group>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    onClick={() => {
                        navigate("/profile")
                    }}
                    leftSection={
                        <User2Icon style={{ width: rem(14), height: rem(14) }} />
                    }
                >
                    {t('header.profile')}
                </Menu.Item>


                <Menu.Divider />

                <Menu.Item
                    leftSection={<LogOut style={{ width: rem(14), height: rem(14) }} />}
                    onClick={async () => {
                        navigate("/");
                        await dispatch(signOutAsync());
                    }}
                >
                    {t('header.logout')}
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
export default UserMenu
