import { api } from "../../app/api"

//TODO dont cache auth profile
export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserDetails: build.query({
      query: () => ({
        url: `/user/profile/`,
        method: "GET",
      }),
    }),
  }),
})

export const { useGetUserDetailsQuery } = authApi
