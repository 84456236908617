import { useEffect, useState } from "react";
import { ActionIcon, Text } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { MoreHorizontal } from "lucide-react";

//Components
import { notification } from "../../../utils/notification";
import PageContainer from "../../../components/PageContainer";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import CrudModal from "../../../components/CrudModal/CrudModal";
import BuildingForm from "./BuildingForm";

//API
import {
    IBuilding,
    useGetBuildingsQuery, useReadBuildingQuery,
    useRemoveBuildingMutation
} from "../../../api/building";
import { useAppSelector } from "../../../app/hooks";
import BuildingInfo from "./BuildingInfo";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";

const initialModalState = {
    opened: false,
    inEdit: false
};
export const [BuildingFormProvider, useBuildingFormContext, useBuildingForm] =
    createFormContext<IBuilding>();
const Building = () => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<{ opened: boolean, record?: "", inEdit: boolean }>(initialModalState);
    const [page, setPage] = useState(1);
    const selectedEntity = useAppSelector(state => state.auth.selectedEntity);
    const [removeBuilding] = useRemoveBuildingMutation();
    const page_size = 10;
    const { data = { results: [], count: 0 }, isFetching } = useGetBuildingsQuery({
        page,
        page_size: 10,
        entity: selectedEntity
    });
    const { data: buildingRecord, isFetching: isFetchingRecord } = useReadBuildingQuery({
            id: Number(modalState.record)
        },
        { skip: !modalState.record });

    const form = useBuildingForm({
        initialValues: {
            name: "",
            entity: selectedEntity
        }
    });

    useEffect(() => {
        form.setFieldValue("entity", selectedEntity);
    }, [selectedEntity]);

    useEffect(() => {
        if (modalState.record && modalState.inEdit) {
            form.setValues({
                ...buildingRecord,
                address: buildingRecord.address ? String(buildingRecord.address.id) : "",
                entity: buildingRecord.entity ? String(buildingRecord.entity.id) : ""
            });
        }
    }, [modalState.record, modalState.inEdit, buildingRecord]);

    async function onDelete() {
        try {
            modalState.record && await removeBuilding({ id: modalState.record }).unwrap();
            notification({ type: "success", title: t("buildings.notifications.deleted") });
            setModalState(initialModalState);
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>{t("buildings.buildings")}</Text>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                columns={[
                    { label: t("buildings.form.name"), name: "name" },
                    {
                        label: t("addresses.address"), name: "country", render: (_, record) => {
                            return `${record.country} ${record.city || ""} ${record.street || ""} ${record?.street_number || ""}`;
                        }
                    },
                    {
                        name: "actions",
                        label: t("common.actions"),
                        type: "actions",
                        width: "50px",
                        render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"} onClick={() => setModalState({
                                    opened: true,
                                    record: record.id,
                                    inEdit: false
                                })}>
                                    <MoreHorizontal />
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => setModalState({ opened: true, inEdit: true })}
                createTitle={t("buildings.create")}
            />
            <CrudModal
                type={"drawer"}
                title={modalState.record ? buildingRecord?.name : t("buildings.create")}
                opened={modalState.opened}
                formId={"buildingsForm"}
                isUpdate={Boolean(modalState.record)}
                onClose={() => {
                    setModalState(initialModalState);
                    form.reset();
                }}
                onDelete={onDelete}
                inEdit={modalState.inEdit}
                setEdit={(isEdit) => {
                    setModalState(prevState => {
                        return {
                            ...prevState,
                            inEdit: isEdit
                        };
                    });
                }}
            >
                <BuildingFormProvider form={form}>
                    {modalState.inEdit ? (
                        <BuildingForm
                            onClose={() => {
                                setModalState(prevState => {
                                    return {
                                        ...prevState,
                                        inEdit: !Boolean(prevState.record),
                                        opened: Boolean(prevState.record)
                                    };
                                });
                            }}
                            recordId={modalState.record}
                        />
                    ) : (modalState.opened && !modalState.inEdit) && (
                        <BuildingInfo record={buildingRecord} isFetching={isFetchingRecord} />
                    )}
                </BuildingFormProvider>
            </CrudModal>
        </PageContainer>
    );
};
export default Building;
