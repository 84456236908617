import Properties from "./Properties/Properties";
import { Flex, Paper } from "@mantine/core";
import Seats from "./Seats/Seats";

const Prices = () => {

    return (
        <Flex>
            <Paper w={"calc(100% - 230px)"} mr={15}>
                <Seats />
            </Paper>
            <Paper w={"230px"}>
                <Properties />
            </Paper>
        </Flex>
    );
};

export default Prices;
