import { Button, Paper } from "@mantine/core";
import { useSetZonesMutation } from "../../../../api/zone";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { toggleLoading } from "../prices.slice";
import { useParams } from "react-router-dom";
import { notification } from "../../../../utils/notification";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../../app/api";

const Actions = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useAppDispatch();
    const updatedSeats = useAppSelector(state => state.schema.updatedSeats);
    const [setZonesApi] = useSetZonesMutation();

    async function setZones() {
        try {
            dispatch(toggleLoading());
            params.id && await setZonesApi({
                event: Number(params.id),
                seats: updatedSeats
            }).unwrap();
            dispatch(toggleLoading());
            notification({ type: "success", title: t(`priceEditor.notifications.pricesSet`) });
        } catch (e) {
            dispatch(toggleLoading());
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data)
            }
            console.log(e);
        }
    }

    return (
        <Paper mt={10} px={10}>
            <Button w={"100%"} onClick={setZones} mt={10}>
                {t(`priceEditor.setPrices`)}
            </Button>
        </Paper>
    );
};

export default Actions;
