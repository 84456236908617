export const text = [
    {
        "properties": {
            "text": "13 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "50",
            "y": "472"
        }
    },
    {
        "properties": {
            "text": "12 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "50",
            "y": "487"
        }
    },
    {
        "properties": {
            "text": "11 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "50",
            "y": "502"
        }
    },
    {
        "properties": {
            "text": "10 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "50",
            "y": "517"
        }
    },
    {
        "properties": {
            "text": "9 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "50",
            "y": "532"
        }
    },
    {
        "properties": {
            "text": "8 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "73",
            "y": "547"
        }
    },
    {
        "properties": {
            "text": "7 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "73",
            "y": "562"
        }
    },
    {
        "properties": {
            "text": "6 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "73",
            "y": "578"
        }
    },
    {
        "properties": {
            "text": " 5",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "73",
            "y": "593"
        }
    },
    {
        "properties": {
            "text": " 4",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "73",
            "y": "608"
        }
    },
    {
        "properties": {
            "text": " 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "73",
            "y": "623"
        }
    },
    {
        "properties": {
            "text": " 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "133",
            "y": "638"
        }
    },
    {
        "properties": {
            "text": " 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "133",
            "y": "653"
        }
    },
    {
        "properties": {
            "text": "13 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "975",
            "y": "472"
        }
    },
    {
        "properties": {
            "text": "12 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "975",
            "y": "487"
        }
    },
    {
        "properties": {
            "text": "11 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "975",
            "y": "502"
        }
    },
    {
        "properties": {
            "text": "10 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "975",
            "y": "517"
        }
    },
    {
        "properties": {
            "text": "9 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "975",
            "y": "532"
        }
    },
    {
        "properties": {
            "text": "8 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "975",
            "y": "547"
        }
    },
    {
        "properties": {
            "text": "7 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "960",
            "y": "562"
        }
    },
    {
        "properties": {
            "text": "6 ",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "960",
            "y": "578"
        }
    },
    {
        "properties": {
            "text": " 5",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "960",
            "y": "593"
        }
    },
    {
        "properties": {
            "text": " 4",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "960",
            "y": "608"
        }
    },
    {
        "properties": {
            "text": " 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "960",
            "y": "623"
        }
    },
    {
        "properties": {
            "text": " 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "900",
            "y": "638"
        }
    },
    {
        "properties": {
            "text": " 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "900",
            "y": "653"
        }
    },
    {
        "properties": {
            "text": " 14",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "82",
            "y": "433"
        }
    },
    {
        "properties": {
            "text": " 15",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "82",
            "y": "418"
        }
    },
    {
        "properties": {
            "text": " 16",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "82",
            "y": "403"
        }
    },
    {
        "properties": {
            "text": " 17",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "82",
            "y": "388"
        }
    },
    {
        "properties": {
            "text": " 18",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "82",
            "y": "373"
        }
    },
    {
        "properties": {
            "text": " 19",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "82",
            "y": "358"
        }
    },
    {
        "properties": {
            "text": " 20",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "99",
            "y": "340"
        }
    },
    {
        "properties": {
            "text": " 21",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "115",
            "y": "323"
        }
    },
    {
        "properties": {
            "text": " 22",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "115",
            "y": "308"
        }
    },
    {
        "properties": {
            "text": " 23",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "130",
            "y": "294"
        }
    },
    {
        "properties": {
            "text": " 24",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "145",
            "y": "277"
        }
    },
    {
        "properties": {
            "text": " 25",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "145",
            "y": "263"
        }
    },
    {
        "properties": {
            "text": "26",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "160",
            "y": "248"
        }
    },
    {
        "properties": {
            "text": "LOJA STÂNGA",
            "type": "text"
        },
        "styles": {
            "fontSize": 13,
            "transform": "rotate(-54, 170, 283)"
        },
        "rect": {
            "x": "175",
            "y": "220"
        }
    },
    {
        "properties": {
            "text": "LOJA 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 13
        },
        "rect": {
            "x": "290",
            "y": "210"
        }
    },
    {
        "properties": {
            "text": "LOJA 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 13
        },
        "rect": {
            "x": "515",
            "y": "210"
        }
    },
    {
        "properties": {
            "text": "LOJA 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 13
        },
        "rect": {
            "x": "750",
            "y": "210"
        }
    },
    {
        "properties": {
            "text": "LOJA DREAPTA",
            "type": "text"
        },
        "styles": {
            "fontSize": 13,
            "transform": "rotate(54, 860, 295)"
        },
        "rect": {
            "x": "850",
            "y": "220"
        }
    },
    {
        "properties": {
            "text": " 14",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "945",
            "y": "433"
        }
    },
    {
        "properties": {
            "text": " 15",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "945",
            "y": "418"
        }
    },
    {
        "properties": {
            "text": " 16",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "945",
            "y": "403"
        }
    },
    {
        "properties": {
            "text": " 17",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "960",
            "y": "386"
        }
    },
    {
        "properties": {
            "text": " 18",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "960",
            "y": "371"
        }
    },
    {
        "properties": {
            "text": " 19",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "945",
            "y": "356"
        }
    },
    {
        "properties": {
            "text": " 20",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "930",
            "y": "341"
        }
    },
    {
        "properties": {
            "text": " 21",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "914",
            "y": "325"
        }
    },
    {
        "properties": {
            "text": " 22",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "914",
            "y": "310"
        }
    },
    {
        "properties": {
            "text": " 23",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "899",
            "y": "294"
        }
    },
    {
        "properties": {
            "text": " 24",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "884",
            "y": "279"
        }
    },
    {
        "properties": {
            "text": " 25",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "884",
            "y": "264"
        }
    },
    {
        "properties": {
            "text": " 26",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "869",
            "y": "249"
        }
    },
    {
        "properties": {
            "text": " 9",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "67",
            "y": "10"
        }
    },
    {
        "properties": {
            "text": " 8",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "67",
            "y": "25"
        }
    },
    {
        "properties": {
            "text": " 7",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "67",
            "y": "40"
        }
    },
    {
        "properties": {
            "text": " 6",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "67",
            "y": "55"
        }
    },
    {
        "properties": {
            "text": " 5",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "67",
            "y": "70"
        }
    },
    {
        "properties": {
            "text": " 4",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "52",
            "y": "85"
        }
    },
    {
        "properties": {
            "text": " 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "52",
            "y": "100"
        }
    },
    {
        "properties": {
            "text": " 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "52",
            "y": "115"
        }
    },
    {
        "properties": {
            "text": " 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "37",
            "y": "130"
        }
    },
    {
        "properties": {
            "text": " 9",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "985",
            "y": "10"
        }
    },
    {
        "properties": {
            "text": " 8",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "985",
            "y": "25"
        }
    },
    {
        "properties": {
            "text": " 7",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "985",
            "y": "40"
        }
    },
    {
        "properties": {
            "text": " 6",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "985",
            "y": "55"
        }
    },
    {
        "properties": {
            "text": " 5",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "985",
            "y": "70"
        }
    },
    {
        "properties": {
            "text": " 4",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "1000",
            "y": "85"
        }
    },
    {
        "properties": {
            "text": " 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "985",
            "y": "100"
        }
    },
    {
        "properties": {
            "text": " 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "985",
            "y": "115"
        }
    },
    {
        "properties": {
            "text": " 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "1000",
            "y": "130"
        }
    },
    {
        "properties": {
            "text": "INTRARE",
            "type": "text"
        },
        "styles": {
            "fontSize": 15
        },
        "rect": {
            "x": "250",
            "y": "100"
        }
    },
    {
        "properties": {
            "text": "BALCON",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "520",
            "y": "120"
        }
    },
    {
        "properties": {
            "text": "INTRARE",
            "type": "text"
        },
        "styles": {
            "fontSize": 15
        },
        "rect": {
            "x": "785",
            "y": "100"
        }
    },
    {
        "properties": {
            "text": " 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "285",
            "y": "190"
        }
    },
    {
        "properties": {
            "text": " 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "285",
            "y": "175"
        }
    },
    {
        "properties": {
            "text": " 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "285",
            "y": "160"
        }
    },
    {
        "properties": {
            "text": " 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "515",
            "y": "190"
        }
    },
    {
        "properties": {
            "text": " 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "515",
            "y": "175"
        }
    },
    {
        "properties": {
            "text": " 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "515",
            "y": "160"
        }
    },
    {
        "properties": {
            "text": " 1",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "750",
            "y": "190"
        }
    },
    {
        "properties": {
            "text": " 2",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "750",
            "y": "175"
        }
    },
    {
        "properties": {
            "text": " 3",
            "type": "text"
        },
        "styles": {
            "fontSize": 10
        },
        "rect": {
            "x": "750",
            "y": "160"
        }
    },
    {
        "properties": {
            "text": "P",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "315",
            "y": "400"
        }
    },
    {
        "properties": {
            "text": "A",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "315",
            "y": "430"
        }
    },
    {
        "properties": {
            "text": "R",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "315",
            "y": "460"
        }
    },
    {
        "properties": {
            "text": "T",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "315",
            "y": "490"
        }
    },
    {
        "properties": {
            "text": "E",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "315",
            "y": "520"
        }
    },
    {
        "properties": {
            "text": "R",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "315",
            "y": "550"
        }
    },
    {
        "properties": {
            "text": "P",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "716",
            "y": "400"
        }
    },
    {
        "properties": {
            "text": "A",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "716",
            "y": "430"
        }
    },
    {
        "properties": {
            "text": "R",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "716",
            "y": "460"
        }
    },
    {
        "properties": {
            "text": "T",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "716",
            "y": "490"
        }
    },
    {
        "properties": {
            "text": "E",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "716",
            "y": "520"
        }
    },
    {
        "properties": {
            "text": "R",
            "type": "text"
        },
        "styles": {
            "fontSize": 20
        },
        "rect": {
            "x": "716",
            "y": "550"
        }
    },
    {
        "properties": {
            "type": "polyline"
        },
        "styles": {
            "stroke": "gray",
            "fill": "none"
        },
        "rect": {
            "points": `
                50,330
                70,330
                168,200
                188,220
                380,220
                400,200
                420,220
                615,220
                635,200
                655,220
                850,220
                870,200
                965,330
                985,330
               `
        }
    }
];
