import { useGetEventsQuery } from "../../api/event";
import { Flex, Select, Switch, Text } from "@mantine/core";
import { CalendarDays, User2 } from "lucide-react";
import { useGetUsersQuery } from "../../api/user";
import { useTranslation } from "react-i18next";
import { DatePickerInput } from "@mantine/dates";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    onChangeByCashier,
    onChangeByEvents,
    onChangeByPrices, onChangeDate,
    setDatesRange,
    setSelectedCashier,
    setSelectedEvent
} from "./reports.slice";
import "dayjs/locale/ru";
import "dayjs/locale/ro";
import EventInputSelect from "../../components/EventInputSelect";
import dayjs from "dayjs";
import { ChangeEvent } from "react";
import { getDayProps } from "../../utils/getDayProps";

const ReportHeader = ({
                          showEventSelect,
                          showCashierSelect,
                          showPeriodSelect,
                          showByPrices,
                          showByEvents,
                          showByCashier,
                          dateType
                      }: {
    showCashierSelect?: boolean,
    showEventSelect?: boolean,
    showPeriodSelect?: boolean,
    showByPrices?: boolean,
    showByEvents?: boolean,
    showByCashier?: boolean,
    dateType: "default" | "range"
}) => {
    const { data = { results: [] } } = useGetEventsQuery({
        page: 1,
        page_size: 9999,
        ordering: "-start"
    });
    const {
        selectedEvent,
        selectedCashier,
        datesRange,
        byEvents,
        byPrices,
        byCashier,
        date
    } = useAppSelector(state => state.reports);
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const { data: usersData = { results: [] } } = useGetUsersQuery({ group: ["cashiers"] });

    function onChangeCashier(v: string) {
        dispatch(setSelectedCashier(Number(v)));
    }

    function onChangeEvent(v: string) {
        dispatch(setSelectedEvent(Number(v)));
    }

    function handleChangeByPrices(e: ChangeEvent<HTMLInputElement>) {
        dispatch(onChangeByPrices(e.target.checked));
    }

    function handleChangeByEvents(e: ChangeEvent<HTMLInputElement>) {
        dispatch(onChangeByEvents(e.target.checked));
    }

    function handleChangeByCashier(e: ChangeEvent<HTMLInputElement>) {
        dispatch(onChangeByCashier(e.target.checked));
    }

    function onChangeDateRange(v: [Date | null, Date | null]) {
        dispatch(setDatesRange([v[0] ? dayjs(v[0] || "").toISOString() : null, v[1] ? dayjs(v[1] || "").toISOString() : null]));
    }

    function onChangeDatePicker(v: Date | null) {
        dispatch(onChangeDate(v ? dayjs(v).toISOString() : null));
    }

    return (
        <Flex justify={"flex-end"} align={"center"}>
            {showByEvents && (
                <Flex ml={10} mr={5} direction={"column"} pb={5} align={"center"}>
                    <Text size={"sm"} pb={5} c={"dimmed"}>{t("reports.table.byEvent")}</Text>
                    <Switch px={5} size={"xs"} onChange={handleChangeByEvents} checked={byEvents} />
                </Flex>
            )}

            {showByPrices && (
                <Flex ml={15} mr={5} direction={"column"} pb={5} align={"center"}>
                    <Text size={"sm"} pb={5} c={"dimmed"}>{t("reports.table.byPrice")}</Text>
                    <Switch size={"xs"} onChange={handleChangeByPrices} checked={byPrices} />
                </Flex>
            )}

            {showByCashier && (
                <Flex ml={15} mr={5} direction={"column"} pb={5} align={"center"}>
                    <Text size={"sm"} pb={5} c={"dimmed"}>{t("reports.table.byCashier")}</Text>
                    <Switch size={"xs"} onChange={handleChangeByCashier} checked={byCashier} />
                </Flex>
            )}

            {showPeriodSelect ? (
                <>
                    <DatePickerInput
                        locale={i18n.language}
                        clearable
                        getDayProps={getDayProps}
                        leftSection={<CalendarDays size={20} />}
                        dropdownType="modal"
                        allowSingleDateInRange
                        valueFormat={"DD-MM-YYYY"}
                        value={dateType === "range" ?
                            [datesRange[0] ? new Date(datesRange[0]) : null, datesRange[1] ? new Date(datesRange[1]) : null] :
                            date ? new Date(date) : null
                        }
                        onChange={dateType === "range" ? onChangeDateRange : onChangeDatePicker}
                        placeholder={t("common.select_date")}
                        miw={210}
                        px={5}
                        modalProps={{
                            zIndex: 999
                        }}
                        type={dateType}
                    />
                </>
            ) : (<></>)}
            {showCashierSelect ? (
                <Select
                    required
                    px={5}
                    leftSection={<User2 size={20} />}
                    onChange={onChangeCashier}
                    value={String(selectedCashier)}
                    placeholder={t("sales.cashier")}
                    data={usersData.results.map((b) => {
                        return { value: String(b.id), label: `${b.first_name} ${b.last_name}` };
                    })}
                    comboboxProps={{
                        zIndex: 500
                    }}
                />
            ) : (<></>)}
            {showEventSelect ? (
                <EventInputSelect
                    onChange={onChangeEvent}
                    value={String(selectedEvent)}
                    data={data.results}
                />
            ) : (<></>)}
        </Flex>
    );
};
export default ReportHeader;