import { api } from "../app/api";
import {IUser} from "./user";


export interface IZone {
    id?: number;
    name?: string;
    price?: number;
    event?: number;
    properties: {
        color: string
    };
}
export type TSeatLog = {
    logs: {
        seat_id: number,
        event_id: number,
        timestamp: string,
        type: "price" | "status" | "reserve" | "cancel_reserve" | "payment" | "refund",
        user?: {
            name: string
        },
        customer?: IUser,
        data: {
            payment_type?: "cash" | "card" | "bank" | "internet",
            amount?: string,
            description?: string,
            price?: string
        },
        document: {
            id: number,
            document_type_id: number
        }
    }[]
}
export type TSeatInfo = {
    id: number,
    event: number,
    hall_part: {id: number, name: string},
    number: number,
    row: {id: number, number: number},
    status: string
    zone: IZone
}
export const zoneApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSeats: build.query<any, any>({
            query: (params) => ({
                url: `/event/admin/events/${params.id}/seats/`
            }),
            providesTags: (result, error, tag) => [{ type: "Zone", tag }]
        }),
        getSeatLog: build.query<TSeatLog, { id: number }>({
            query: (params) => ({
                url: `/event/admin/seats/${params.id}/log/`
            }),
            providesTags: (result, error, tag) => [{ type: "Seat", tag }]
        }),
        getSeatInfo: build.query<TSeatInfo, { id: number }>({
            query: (params) => ({
                url: `/event/admin/seats/${params.id}/`
            }),
            providesTags: (result, error, tag) => [{ type: "Seat", tag }]
        }),
        getZones: build.query<IZone[], { id: string }>({
            query: (params) => ({
                url: `/event/admin/zones/`,
                params: {
                    event: params.id
                }
            }),
            providesTags: (result, error, tag) => [{ type: "Zone", tag }]
        }),
        readZone: build.query<any, any>({
            query: (params) => ({
                url: `/event/admin/zones/${params.id}`
            }),
            providesTags: (result, error, tag) => [{ type: "Zone", tag }]
        }),
        setZones: build.mutation<any, { event: number, seats: { seat: number, zone: number | null }[] }>({
            query: (payload) => ({
                url: "/event/admin/seats/set-zones/",
                method: "POST",
                body: payload
            })
        }),
        createZone: build.mutation<any, any>({
            query: (payload) => ({
                url: `/event/admin/zones/`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "Zone" }]
        }),
        updateZone: build.mutation<any, any>({
            query: (payload) => ({
                url: `/event/admin/zones/${payload.id}`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: [{ type: "Zone" }]
        }),
        removeZone: build.mutation<any, any>({
            query: (payload) => ({
                url: `/event/admin/zones/${payload}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "Zone" }]
        })

    })
});

export const {
    useGetSeatsQuery,
    useGetSeatLogQuery,
    useLazyGetSeatsQuery,
    useGetSeatInfoQuery,
    useGetZonesQuery,
    useLazyGetZonesQuery,
    useReadZoneQuery,
    useSetZonesMutation,
    useCreateZoneMutation,
    useUpdateZoneMutation,
    useRemoveZoneMutation
} = zoneApi;
