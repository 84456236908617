import InfoTabs from "../../../components/CrudModal/InfoTabs";
import InfoItem from "../../../components/CrudModal/InfoItem";
import { IHall } from "../../../api/hall";
import { useTranslation } from "react-i18next";

interface IHallRead extends Omit<IHall, "building"> {
    building: {
        id: number,
        name: string
    };
}

const HallInfo = ({ record, isFetching }: { record: IHallRead, isFetching: boolean }) => {
    const { t } = useTranslation();
    return (
        <InfoTabs isFetching={isFetching} record={record}>
            {!isFetching && (
                <>
                    <InfoItem label={t("halls.form.name")} value={record.name} />
                    <InfoItem label={t("halls.form.building")} value={record.building.name} />
                </>
            )}
        </InfoTabs>
    );
};

export default HallInfo;
