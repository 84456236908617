import React, { useEffect, useState } from "react";
import { Accordion, Checkbox, Group, Paper, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import classes from "./AllowToBuy.module.css";
import {
    useCreateEntityPermissionsMutation,
    useGetEntityPermissionsQuery, useRemoveEntityPermissionsMutation
} from "../../../../../api/entity-permissions";
import { useAppSelector } from "../../../../../app/hooks";
import { notification } from "../../../../../utils/notification";
import { showErrorMessages } from "../../../../../app/api";
import { IEntity, useGetEntitiesQuery } from "../../../../../api/entity";

type TBoxOffice = IEntity & { isSelected: boolean, permissionId?: number }

const AllowToBuy = () => {
    const { id: eventID } = useAppSelector(state => state.schema.event);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [entities, setEntities] = useState<TBoxOffice[]>([]);
    const [createEntityPermission] = useCreateEntityPermissionsMutation();
    const [removeEntityPermission] = useRemoveEntityPermissionsMutation();
    const { data: entityData = { results: [] } } = useGetEntitiesQuery({});
    const { data: permissionsData = { results: [] } } = useGetEntityPermissionsQuery({ event: eventID }, { skip: !eventID });

    useEffect(() => {
        if (entityData.results.length) {
            try {
                setEntities(entityData.results.map(entity => {
                    const permission = permissionsData.results.find(p => p.entity.id === entity.id);
                    console.log(permission);
                    return {
                        ...entity,
                        isSelected: Boolean(permission),
                        permissionId: permission?.id
                    };
                }));
            } catch (e) {
                console.log(e);
            }
        }

    }, [JSON.stringify(permissionsData), JSON.stringify(entityData), eventID]);

    async function onChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>, entityId: number, permissionId?: number) {
        try {
            const isSelected = !e.target.checked;
            if (!eventID) return;
            setIsLoading(true);
            if (!isSelected) {
                try {
                    await createEntityPermission({ event: eventID, entity: entityId }).unwrap();
                    notification({ type: "success", title: t("boxOffices.notifications.created") });
                    setIsLoading(false);
                } catch (e) {
                    showErrorMessages(e);
                    setIsLoading(false);
                }
                return;
            }
            if (isSelected && permissionId) {
                try {
                    await removeEntityPermission({ id: permissionId }).unwrap();
                    notification({ type: "success", title: t("boxOffices.notifications.deleted") });
                    setIsLoading(false);
                } catch (e) {
                    showErrorMessages(e);
                    setIsLoading(false);
                }
            }
        } catch (e) {
            notification({ type: "error", title: t("common.errorDefaultMessage") });
        }
    }

    return (
        <Paper>
            <Accordion classNames={{ control: classes.control, content: classes.content, label: classes.label }}>
                <Accordion.Item value={"1"}>
                    <Accordion.Control><Text size={"sm"}
                                             c={"dimmed"}>{t("priceEditor.allowToBuy")}</Text></Accordion.Control>
                    <Accordion.Panel>
                        <Paper>
                            {entities.map(entity => {
                                return (
                                    <Group key={entity.id}>
                                        <Checkbox
                                            disabled={isLoading}
                                            checked={entity.isSelected}
                                            onChange={e => onChangeCheckbox(e, entity.id || 0, entity.permissionId)}
                                            label={entity.name} mt={10} />
                                    </Group>
                                );
                            })}
                        </Paper>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Paper>
    );
};

export default AllowToBuy;
