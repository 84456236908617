import {api} from "../app/api";
import {IUser} from "./user";
import {IEvent} from "./event";


export type TTicketSalesResponse = {
    event: { id: number, name: string, start: string, finish: string },
    header: {
        source: string,
        sold_quantity: number,
        sold_amount: number,
        refunded_quantity: number,
        refunded_amount: number,
    }[],
    rows: {
        place: string,
        zone__price: number,
        offered_quantity: number,
        offered_amount: number,
        sold_quantity: number,
        sold_amount: number,
        refunded_quantity: number,
        refunded_amount: number,
        unsold_quantity: number,
        unsold_amount: number,
        revenue: number
    }[]
}

export type TZoneSalesResponse = {
    event: { id: number, name: string, start: Date, finish: Date },
    rows: {
        zone__price: string,
        offered_quantity: number,
        offered_amount: number,
        sold_quantity: number,
        sold_cash_amount: number,
        sold_card_amount: number,
        sold_bank_amount: number,
        sold_internet_amount: number,
        refunded_quantity: number,
        refunded_amount: number,
        unsold_quantity: number,
        unsold_amount: number,
        revenue: number,
    }[]
}

export type TCashiersSalesResponse = {
    cashier: IUser,
    rows: {
        event: IEvent,
        price: number,
        sold_quantity: number,
        sold_cash_amount: number,
        sold_card_amount: number,
        refunded_quantity: number,
        refunded_amount: number,
        revenue: number
    }[]
}

export type TBoxOfficeSalesResponse = {
    timestamp: string,
    event: IEvent,
    rows: {
        cashier: string,
        date: string,
        refunded_quantity: number,
        sold_quantity: number,
        sold_amount: number
    }[]
}

export type TPriceListResponse = {
    timestamp: string,
    event: IEvent,
    rows: {
        hall_part: string,
        row_number: number,
        price: number,
        quantity: number,
        sum: number,
        seats: string
    }[]
}

export type TDailySalesResponse = {
    count: number,
    timestamp: string,
    rows: {
        date: string,
        payment_type: 'cash' | 'card' | 'bank_transfer' | 'internet',
        sales_quantity: number,
        sales_amount: number
    }[]
}
export const analyticsApi = api.injectEndpoints({
    endpoints: (build) => ({
        ticketSales: build.query<TTicketSalesResponse, {
            page?: number, page_size?: number, event?: number,
            date_lte?: string | null, date_gte?: string | null
        }>({
            query: (params) => ({
                url: `/sales/analytics/ticket-sales/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    event: params.event,
                    date_lte: params.date_lte,
                    date_gte: params.date_gte,
                }
            }),
            providesTags: (result, error, tag) => [{type: "Analytics", tag}]
        }),
        zoneSales: build.query<TZoneSalesResponse, {
            page?: number, page_size?: number, event?: number,
            date_lte?: string | null,
        }>({
            query: (params) => ({
                url: `/sales/analytics/zone-sales/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    event: params.event,
                    date_lte: params.date_lte
                }
            }),
            providesTags: (result, error, tag) => [{type: "Booking", tag}]
        }),
        cashierSales: build.query<TCashiersSalesResponse, {
            page?: number,
            page_size?: number,
            cashier: number,
            by_event?: boolean,
            by_price?: boolean,
            date_gte?: string | null,
            date_lte?: string | null,
        }>({
            query: (params) => ({
                url: `/sales/analytics/cashier-sales/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    cashier: params.cashier,
                    date_lte: params.date_lte,
                    date_gte: params.date_gte,
                    by_event: params.by_event,
                    by_price: params.by_price
                }
            }),
            providesTags: (result, error, tag) => [{type: "Booking", tag}]
        }),
        boxOfficeSales: build.query<TBoxOfficeSalesResponse, {
            page?: number,
            page_size?: number,
            event: number,
            date_gte?: string | null,
            date_lte?: string | null,
            by_cashier?: boolean,
        }>({
            query: (params) => ({
                url: `/sales/analytics/box-office-sales/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    event: params.event,
                    by_cashier: params.by_cashier,
                    date_lte: params.date_lte,
                    date_gte: params.date_gte
                }
            }),
            providesTags: (result, error, tag) => [{type: "Booking", tag}]
        }),
        priceList: build.query<TPriceListResponse, {
            event?: number,
        }>({
            query: (params) => ({
                url: `/event/admin/events/${params.event}/price-list/`,
                params: {
                    id: params.event
                }
            }),
            providesTags: (result, error, tag) => [{type: "Analytics", tag}]
        }),
        dailySales: build.query<TDailySalesResponse, {
            event?: number,
            paymentType?: 'cash' | 'card' | 'bank_transfer' | 'internet',
            date_lte?: string,
            date_gte?: string,
            ordering?: string,
            page?: number,
            pageSize?: number
        }>({
            query: (params) => ({
                url: `/sales/analytics/daily-sales/`,
                params: {
                    event: params.event,
                    date_lte: params.date_lte,
                    date_gte: params.date_gte,
                    ordering: params.ordering,
                    page: params.page,
                    pageSize: params.pageSize
                }
            }),
            providesTags: (result, error, tag) => [{type: "Analytics", tag}]
        }),
    })
});

export const {
    useZoneSalesQuery,
    useTicketSalesQuery,
    useCashierSalesQuery,
    useBoxOfficeSalesQuery,
    usePriceListQuery,
    useDailySalesQuery,
    useLazyDailySalesQuery,
} = analyticsApi;
