import { api } from "../app/api";
import { IUser } from "./user";

export interface IEntityPermissions {
    id: number;
    event: {
        id: number,
        name: string,
        start: Date,
        finish: Date
    },
    entity: {
      id: number,
      name: string,
    },
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IEntityPermissions[];
}

interface IQueryParams {
    event?: number;
    ordering?: string;
    page?: number;
    page_size?: number;
}

export const EntityPermissionsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEntityPermissions: build.query<IQueryResponse, IQueryParams>({
            query: (params) => ({
                url: `/event/admin/entity-permissions/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    event: params.event
                }
            }),
            providesTags: (result, error, tag) => [{ type: "EntityPermissions", tag }]
        }),
        readEntityPermissions: build.query<IEntityPermissions, { id: number }>({
            query: (params) => ({
                url: `/event/admin/entity-permissions/${params.id}/`,
                params: {
                    id: params.id
                }
            }),
            providesTags: (result, error, tag) => [{ type: "EntityPermissions", tag }]
        }),
        createEntityPermissions: build.mutation<any, {entity: number, event: number}>({
            query: (payload) => ({
                url: `/event/admin/entity-permissions/`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "EntityPermissions" }]
        }),
        updateEntityPermissions: build.mutation<any, any>({
            query: (payload) => ({
                url: `/event/admin/entity-permissions/${payload.id}`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: [{ type: "EntityPermissions" }]
        }),
        removeEntityPermissions: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/event/admin/entity-permissions/${payload.id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "EntityPermissions" }]
        })
    })
});

export const {
    useGetEntityPermissionsQuery,
    useCreateEntityPermissionsMutation,
    useRemoveEntityPermissionsMutation,
} = EntityPermissionsApi;
