import React from "react";
import { Paper, Skeleton, Tabs } from "@mantine/core";
import AuditRecord from "./AuditRecord";
import { IUser } from "../../api/user";
import { useTranslation } from "react-i18next";

interface IProps {
    children: React.ReactNode;
    isFetching: boolean;
    record: {
        author?: IUser
        modifier?: IUser
        created?: Date
        modified?: Date
    };
}

const InfoTabs = ({ record, isFetching, children }: IProps) => {
    const { t } = useTranslation();
    return (
        <Paper>
            {isFetching ? (
                <>
                    <Skeleton h={25} mt={10} />
                    <Skeleton h={25} mt={10} />
                    <Skeleton h={25} mt={10} />
                    <Skeleton h={25} mt={10} />
                </>
            ) : (
                <Tabs defaultValue={"info"} variant={"outline"}>
                    <Tabs.List grow>
                        <Tabs.Tab value={"info"}>{t('common.details')}</Tabs.Tab>
                        <Tabs.Tab value={"audit"}>{t('common.audit')}</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value={"info"} p={10} pt={30}>
                        {children}
                    </Tabs.Panel>
                    <Tabs.Panel value={"audit"} p={10} pt={30}>
                        <AuditRecord modified={record.modified} modifier={record.modifier}
                                     author={record.author}
                                     created={record.created}
                        />
                    </Tabs.Panel>
                </Tabs>
            )}
        </Paper>
    );
};

export default InfoTabs;
