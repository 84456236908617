import { api } from "../app/api";
import { IBoxOffice } from "./box-office";
import { IUser } from "./user";

interface SalesSessionInfo {
    box_office: IBoxOffice,
    cashier: IUser,
    closed_by: IUser,
    id: number,
    is_active: boolean
}

interface SessionStatus {
    last_session: SalesSessionInfo,
    token_session: SalesSessionInfo
}

export const buildingApi = api.injectEndpoints({
    endpoints: (build) => ({
        startSales: build.mutation<{ token: string }, {
            boxOfficeId: number
        }>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.boxOfficeId}/start-sales/`,
                method: "POST",
                body: {
                    id: payload.boxOfficeId
                }
            }),
            invalidatesTags: [{ type: "BoxOffice" }]
        }),
        completeSales: build.mutation<any, { boxOfficeId: number }>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.boxOfficeId}/complete-sales/`,
                method: "POST",
                body: {
                    id: payload.boxOfficeId
                }
            }),
            invalidatesTags: [{ type: "BoxOffice" }]
        }),
        sessionStatus: build.query<SessionStatus, { token: string }>({
            query: (payload) => ({
                url: "/sales/admin/box-offices/session-status/",
                method: "GET",
                params: {
                    token: payload.token
                }
            })
        }),
        buyTickets: build.mutation<any, {
            boxOfficeId: number,
            token: string,
            payment_type: string,
            items: { seat: number }[],
            customer?: number,
            booking?: number,
            event: number
        }>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.boxOfficeId}/buy/`,
                method: "POST",
                body: {
                    token: payload.token,
                    items: payload.items,
                    customer: payload.customer,
                    event: payload.event,
                    booking: payload.booking,
                    payment_type: payload.payment_type
                }
            }),
            invalidatesTags: ["Scheme", "Booking"]
        }),
        refundTickets: build.mutation<any, {
            boxOfficeId: number,
            token: string,
            items: { ticket: number }[],
            customer?: number,
            event: number
        }>({
            query: (payload) => ({
                url: `/sales/admin/box-offices/${payload.boxOfficeId}/refund/`,
                method: "POST",
                body: {
                    token: payload.token,
                    items: payload.items,
                    event: payload.event
                }
            }),
            invalidatesTags: ["Scheme", "Booking"]
        }),

    })
});

export const {
    useBuyTicketsMutation,
    useStartSalesMutation,
    useCompleteSalesMutation,
    useRefundTicketsMutation,
    useLazySessionStatusQuery,
} = buildingApi;
