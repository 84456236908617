import {PrinterIcon} from "lucide-react";
import {ActionIcon} from "@mantine/core";
import dayjs from "dayjs";

const ReportPrint = ({handlePrint}: { handlePrint: () => void }) => {

    return (
        <>
            <ActionIcon
                style={{position: 'absolute', right: 10, top: 20}}
                variant={"transparent"} ml={10} mr={10} onClick={handlePrint}>
                <PrinterIcon/>
            </ActionIcon>
            <div id={"print_timestamp"}>
                {dayjs(new Date()).format("DD-MM-YYYY HH:mm")}
            </div>
        </>
    );
};
export default ReportPrint;