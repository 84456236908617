import InfoTabs from "../../../components/CrudModal/InfoTabs";
import InfoItem from "../../../components/CrudModal/InfoItem";
import { IBoxOffice } from "../../../api/box-office";
import { IUser } from "../../../api/user";
import { useTranslation } from "react-i18next";

interface IBoxOfficeRead extends Omit<IBoxOffice, "address" | "cashiers"> {
    address?: {
        id: number
        country: string
        city: string
        street: string
        street_number: string
    };
    cashiers?: IUser[];
}

const BoxOfficeInfo = ({ record, isFetching }: { record: IBoxOfficeRead, isFetching: boolean }) => {
    const { t } = useTranslation();
    return (
        <InfoTabs isFetching={isFetching} record={record}>
            {!isFetching && (
                <>
                    <InfoItem label={t("boxOffices.form.name")} value={record.name} />
                    <InfoItem label={t("boxOffices.form.cashiers")} value={record.cashiers?.map((v) => v.first_name).join(", ")} />
                    <InfoItem label={t("boxOffices.form.address")} value={
                        `${record?.country} ${record?.city || ""} ${record?.street || ""} ${record?.street_number || ""}`} />
                </>
            )}
        </InfoTabs>
    );
};

export default BoxOfficeInfo;
