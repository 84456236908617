import {api} from "../app/api";
import {IUser} from "./user";
import {URLSearchParamParser} from "./urlSearchParamParser";
import {IEvent} from "./event";

export interface IBookingTicket {
    id: number,
    status: "free" | "reserved" | "sold" | "disabled",
    seat: number,
    key: string,
    zone: {
        id: number,
        name: string,
        price: string,
    },
    amount: string
}

export interface IBooking {
    id?: number;
    tickets: IBookingTicket[];
    mode: "staff" | "admin" | "customer" | "customer_long",
    event: {
        id: number,
        name: string,
        start: string,
        finish: string,
        image?: string
    };
    description?: string;
    customer: number | null
    status: string
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

export interface IBookingModify {
    id?: number;
    tickets: IBookingTicket[];
    event: IEvent;
    description?: string;
    customer: number | null

    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IBooking[];
}

interface IQueryParams {
    search?: string;
    event?: number;
    customer?: number;
    ordering?: string;
    page?: number;
    page_size?: number;
    status?: "active" | "deactivated" | "sold" | "refunded" | "used" | any[],
    mode?: "staff" | "guest" | "customer" | "system" | "customer_long" | "admin" | any[]
}

export const bookingApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBookings: build.query<IQueryResponse, IQueryParams>({
            query: (params) => {
                const queryString = URLSearchParamParser(params)
                return {
                    url: `/event/admin/bookings/?${queryString}`,
                }
            },
            providesTags: ["Booking"]
        }),
        readBooking: build.query<any, any>({
            query: (params) => ({
                url: `/event/admin/bookings/${params.id}`
            }),
            providesTags: ["Booking"]
        }),
    })
});

export const {
    useGetBookingsQuery,
    useReadBookingQuery
} = bookingApi;
