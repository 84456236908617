import InfoTabs from "../../components/CrudModal/InfoTabs";
import InfoItem from "../../components/CrudModal/InfoItem";
import { IUser } from "../../api/user";
import { useTranslation } from "react-i18next";



const UsersInfo = ({ record, isFetching }: { record: IUser, isFetching: boolean }) => {
    const { t } = useTranslation();
    const sex = { male: t("users.form.sexItems.male"), female: t("users.form.sexItems.female") };
    return (
        <InfoTabs record={record} isFetching={isFetching}>
            {!isFetching && (
                <>
                    <InfoItem value={record?.first_name}
                              label={t("users.form.firstName")} />
                    <InfoItem value={record?.last_name}
                              label={t("users.form.lastName")} />
                    <InfoItem value={record?.email}
                              label={"E-mail"} />
                    <InfoItem value={record?.phone}
                              label={t("users.form.phone")} />
                    <InfoItem value={record?.sex ? sex[record.sex] : ""}
                              label={t("users.form.sex")} />
                    <InfoItem value={record?.groups?.map((gr: { name: string }) => gr.name).join(", ")}
                              label={t("users.form.groups")} />
                </>
            )}
        </InfoTabs>
    );
};

export default UsersInfo;
