import InfoTabs from "../../../components/CrudModal/InfoTabs";
import { IEntity } from "../../../api/entity";
import InfoItem from "../../../components/CrudModal/InfoItem";
import { useTranslation } from "react-i18next";

interface IEntityRead extends Omit<IEntity, "address"> {
    address?: {
        id: number
        country: string
        city: string
        street: string
        street_number: string
    };
}

const EntityInfo = ({ record, isFetching }: { record: IEntityRead, isFetching: boolean }) => {
    const { t } = useTranslation();
    return (
        <InfoTabs isFetching={isFetching} record={record}>
            {!isFetching && (
                <>
                    <InfoItem label={t("entities.form.name")} value={record.name} />
                    <InfoItem label={t("entities.form.address")} value={
                        `${record?.country} ${record?.city || ""} ${record?.street || ""} ${record?.street_number || ""}`} />
                </>
            )}
        </InfoTabs>
    );
};

export default EntityInfo;
