import classes from "./Header.module.css";
import {
    ActionIcon, useComputedColorScheme,
    useMantineColorScheme, useMantineTheme
} from "@mantine/core";
import { SunDim, Moon } from "lucide-react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setUserLanguage } from "../../app/app.slice";
import UserMenu from "./UserMenu";
import BoxOfficeChanger from "./BoxOfficeChanger";
import { useTranslation } from "react-i18next";
import { roleCheck } from "../../utils/roleCheck";

const Header = () => {
    const roles = useAppSelector(state => state.auth.user.role);
    return (
        <div className={classes.header}>
            <div className={classes.actions}>
                {roleCheck(roles, ["cashiers"]) && <BoxOfficeChanger />}
                <ThemeChanger />
                <LanguageChanger />
                <UserMenu />
            </div>
        </div>
    );
};

export default Header;


export const LanguageChanger = () => {
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    return (
        <ActionIcon
            size={"lg"}
            mr={"xs"}
            variant={"light"}
            onClick={() => {
                const currentLng = i18n.language === "ru" ? "ro" : "ru";
                i18n.changeLanguage(currentLng);
                dispatch(setUserLanguage(currentLng));
            }}
        >
            {(i18n.language).toUpperCase()}
        </ActionIcon>
    );
};


export const ThemeChanger = () => {
    const { toggleColorScheme } = useMantineColorScheme();
    const theme = useMantineTheme();
    const computedColorScheme = useComputedColorScheme("dark", {
        getInitialValueInEffect: true
    });

    return (
        <ActionIcon
            onClick={() => toggleColorScheme()}
            size="lg"
            mr={"xs"}
            variant={"light"}
        >
            {computedColorScheme === "dark" ? (
                <SunDim color={theme.colors.yellow[3]} />
            ) : (
                <Moon color={theme.colors.dark[9]} size={20} />
            )}
        </ActionIcon>
    );
};

