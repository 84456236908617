import Schema from "../../components/Schema/Schema";
import { IZone, useLazyGetSeatsQuery, useLazyGetZonesQuery } from "../../api/zone";
import { useLazyReadSchemeQuery } from "../../api/scheme";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyReadEventQuery } from "../../api/event";
import merge from "deepmerge-json";
import { SeatsDataSchema } from "../sales/sales.slice";
import { Box, Flex, Paper } from "@mantine/core";
import "./buy-widget.css"
import classes from "./BuyWidget.module.css"

const tickets = [{id: 1},{id: 2},{id: 3},{id: 4},]
const BuyWidget = () => {
    const params = useParams();
    const [data, setData] = useState<SeatsDataSchema>({ data: {} });
    const [zones, setZones] = useState<IZone[]>([]);
    const [getZones] = useLazyGetZonesQuery();
    const [readEvent] = useLazyReadEventQuery();
    const [getSeats] = useLazyGetSeatsQuery();
    const [readScheme] = useLazyReadSchemeQuery();

    useEffect(() => {
        getInitialData();
    }, []);

    async function getInitialData() {
        try {
            if (params.id) {
                const event = await readEvent({ id: Number(params.id) }).unwrap();
                const zones = await getZones({ id: params.id }).unwrap();
                const eventSeats = await getSeats({ id: params.id }).unwrap();
                const scheme = await readScheme({ id: event.scheme }).unwrap();
                setData({
                    data: merge(scheme.data, eventSeats)
                });
                setZones(zones);
            }

        } catch (e) {

        }
    }

    return (
        <Flex>
            <Box w={'100%'} h={400} bg={"white"}>
                <Schema data={data} zones={zones} widgetMode type={"sales"} />
            </Box>
            <Box w={150} ml={5}>
                {tickets.map((ticket, index) => {
                    return(
                        <Paper shadow={"md"} p={3} radius={"md"} mb={5}> 1 </Paper>
                    )
                })}
            </Box>
        </Flex>
    );
};
export default BuyWidget;